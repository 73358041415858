import { render, staticRenderFns } from "./OrderAccessoryTable.vue?vue&type=template&id=e08f9f4a&scoped=true&"
import script from "./OrderAccessoryTable.vue?vue&type=script&lang=js&"
export * from "./OrderAccessoryTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e08f9f4a",
  null
  
)

export default component.exports