export default {
    "BO1": {
        "measurement_place": null,
        "description": null,
        "sizes": {}
    },
    "D01": {
        "measurement_place": null,
        "description": null,
        "sizes": {}
    },
    "D10": {
        "measurement_place": null,
        "description": null,
        "sizes": {}
    },
    "D11": {
        "measurement_place": null,
        "description": null,
        "sizes": {}
    },
    "C22a": {
        "measurement_place": null,
        "description": null,
        "sizes": {}
    },
    "C13": {
        "measurement_place": null,
        "description": null,
        "sizes": {}
    },
    "C15 B": {
        "measurement_place": null,
        "description": null,
        "sizes": {}
    },
    "28,20C06": {
        "measurement_place": null,
        "description": null,
        "sizes": {}
    },
    "C09": {
        "measurement_place": null,
        "description": null,
        "sizes": {}
    },
    "C11": {
        "measurement_place": null,
        "description": null,
        "sizes": {}
    },
    "A04a.dist": {
        "measurement_place": null,
        "description": null,
        "sizes": {}
    },
    "A04": {
        "measurement_place": null,
        "description": null,
        "sizes": {}
    },
    "A10": {
        "measurement_place": null,
        "description": null,
        "sizes": {}
    },
    "A12": {
        "measurement_place": null,
        "description": null,
        "sizes": {}
    },
    "A16": {
        "measurement_place": null,
        "description": null,
        "sizes": {}
    }
};