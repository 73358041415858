var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"instagram-post rounded-lg gray-border-1px"},[_c('div',{staticClass:"instagram-post-top"},[_c('div',{staticClass:"instagram-post-avatar"},[_c('img',{attrs:{"src":_vm.content.user.avatar_file}})]),_c('div',{staticClass:"instagram-post-name"},[_vm._v(" "+_vm._s(_vm.content.user.name)+" ")]),_c('div',{staticClass:"instagram-post-title"},[_vm._v(" "+_vm._s(_vm.$t('model_house.sample_responsible_person'))+" ")]),_c('div',{staticClass:"instagram-post-date"},[_c('button',{staticClass:"btn font-weight-bold cursor-pointer btn-icon btn-circle mr-3",attrs:{"type":"button","disabled":!_vm.isSharable},on:{"click":_vm.copyToClipboard}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIconByKey('icons.meeting.share-2', {
                  class: 'w-50px h-50px',
                  style: `'opacity: 0.4;'`
                }))}})]),_c('button',{staticClass:"btn font-weight-bold cursor-pointer btn-icon btn-circle",attrs:{"type":"button","disabled":!_vm.isDeletable},on:{"click":_vm.confirmDelete}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getIconByKey('icons.meeting.delete', {
          class: 'w-50px h-50px',
          style: `${!_vm.isDeletable ? 'opacity: 0.4;': ''}`
        }))}})])])]),_c('div',{staticClass:"instagram-post-bottom"},[_c('div',{staticClass:"instagram-post-desc"},[_vm._v(" "+_vm._s(_vm.content.content)+" ")]),_c('input',{ref:"copyLink",staticClass:"form-control pl-2 pr-2 d-none invitation-input",attrs:{"type":"text"},domProps:{"value":_vm.content.content}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }