<template>
  <div class="instagram-post rounded-lg gray-border-1px">
    <div class="instagram-post-top">
      <div class="instagram-post-avatar">
        <img :src="content.user.avatar_file">
      </div>
      <div class="instagram-post-name">
        {{ content.user.name }}
      </div>
      <div class="instagram-post-title">
        {{ $t('model_house.sample_responsible_person') }}
      </div>
      <div class="instagram-post-date">
        <button
            type="button"
            :disabled="!isSharable"
            @click="copyToClipboard"
            class="btn font-weight-bold cursor-pointer btn-icon btn-circle mr-3">
                  <span v-html="getIconByKey('icons.meeting.share-2', {
                    class: 'w-50px h-50px',
                    style: `'opacity: 0.4;'`
                  })"
                  ></span>
        </button>
        <button
            type="button"
            :disabled="!isDeletable"
            @click="confirmDelete"
            class="btn font-weight-bold cursor-pointer btn-icon btn-circle ">
          <span v-html="getIconByKey('icons.meeting.delete', {
            class: 'w-50px h-50px',
            style: `${!isDeletable ? 'opacity: 0.4;': ''}`
          })"
          ></span>
        </button>
      </div>
    </div>
    <div class="instagram-post-bottom">
      <div class="instagram-post-desc">
        {{ content.content }}
      </div>

      <input
          ref="copyLink"
          :value="content.content"
          class="form-control pl-2 pr-2 d-none invitation-input"
          type="text"
      />
    </div>
  </div>
</template>

<script>
import AudioPlayer from "@/assets/components/mediaPlayer/AudioPlayer";

export default {
  name: "MeetingTextPlayerHolderCard",
  props: {
    content: {
      required: true,
      type: Object,
    },
    isDeletable: {
      required: false,
      default: false,
      type: Boolean
    },
    isSharable: {
      required: false,
      default: false,
      type: Boolean
    },

  },
  methods: {
    copyToClipboard() {
      let copyText = this.$refs.copyLink;
      copyText.classList.remove("d-none");
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */
      document.execCommand("copy");
      copyText.classList.add("d-none");
      this.sweetAlertSuccess(this.$t('general.copy_to_clipboard_text'));
    },
    confirmDelete() {
      let self = this;
      this.sweetAlertConfirm('general.are_you_sure').then((result) => {
        if (result) {
          self.$emit('onClickDelete', self.content.id)
        }
      })
    }
  }
}
</script>

<style scoped>
.rounded-full {
  border-radius: 99999px !important;
}

.text-holder-body {
  max-height: 200px;
  overflow-y: auto;
}

.instagram-post {
  position: relative;
  margin: 0 auto;
  background-color: #ffffff;
  overflow: hidden;
  cursor: default;
  max-width: 100%;
}
.instagram-post-top {
  position: relative;
  height: 60px;
  overflow: hidden;
  border-bottom: 1px solid #e2e2e2;
}
.instagram-post-avatar {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 40px;
  width: 40px;
  overflow: hidden;
  border-radius: 100%;
}
.instagram-post-avatar img{
  width: 100%;
}
.instagram-post-name {
  position: absolute;
  top: 12px;
  left: 60px;
  text-transform: capitalize;
  font-weight: bold;
  color: #000;
  font-size: 15px;
}
.instagram-post-title {
  position: absolute;
  left: 59px;
  top: 34px;
  font-size: 12px;
}
.instagram-post-date {
  float: right;
  padding-top: 10px;
  padding-right: 10px;
}

.instagram-post-bottom {
  position: relative;
}
.instagram-post-bottom .instagram-post-desc {
  height: 350px;
  overflow: hidden;
}
.instagram-post-bottom {
  position: relative;
}
.instagram-post-desc {
  position: relative;
  width: 100%;
  min-height: 50px;
  margin: 20px auto 0 auto;
  border-bottom: 1px solid #dedede;
  overflow: hidden;
  padding: 0 15px;
}
.instagram-post-desc div {
  position: relative;
  font-size: 10px;
  color: #707070;
}
</style>
