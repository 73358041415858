<template>
  <div>
    <div class="w-100 d-flex justify-content-center mt-2 items-center">
      <button-with-icon
          v-if="showSubmitButton"
          :icon-name="null"
          :text="$t(isEditing ? 'order.update_instruction' : 'order.create_instruction').toUpperCase()"
          btn-classes="text-primary"
          size="md"
          @onClick="$emit('submit')"
      ></button-with-icon>
    </div>
    <div v-if="model.pdf_link" class="w-100 d-flex justify-content-center mt-2 items-center">
      <div v-if="!isEditing" class="w-150px">
        <button-with-icon
            :icon-name="null"
            :text="$t('order.download_as_pdf').toUpperCase()"
            class="mx-1"
            @onClick="openInNewTab(model.pdf_link)"
        ></button-with-icon>
      </div>
    </div>

    <div v-if="!isEditing && model.id"
         class="w-100 d-flex justify-content-center mt-2 items-center">
      <div class="w-150px">
        <button-with-icon
            :icon-name="null"
            :text="$t('order.edit').toUpperCase()"
            class="mx-1"
            @onClick="$emit('update:isEditing', true)"
        ></button-with-icon>
      </div>

      <div class="w-150px">
        <button-with-icon
            :btn-styles="{border: '1px solid red !important', color: 'red !important'}"
            :icon-name="null"
            :text="$t('order.cancel_instruction').toUpperCase()"
            class="mx-1"
            @onClick="onClickCancelInstruction"
        ></button-with-icon>
      </div>

      <div class="w-150px">
        <button-with-icon
            :icon-name="null"
            :text="$t('order.send').toUpperCase()"
            class="mx-1"
            @onClick="sendMail"
        ></button-with-icon>
      </div>

      <div class="w-150px" v-if="showRepeatButton">
          <button-with-icon
            :icon-name="null"
            :text="$t('sample.repeat_instruction').toUpperCase()"
            class="mx-1"
            @onClick="onClickRepeatRequest"
        ></button-with-icon>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import {CREATE_ITEM} from "@/core/services/store/REST.module";

export default {
  name: "BottomButtons",
  components: {CustomMultiSelect, ButtonWithIcon},
  props: {
    isValidToSubmit: {
      required: true,
      type: Boolean,
    },
    isEditing: {
      required: true,
      type: Boolean,
    },
    showRepeatButton: {
      required: false,
      default: false,
      type: Boolean,
    },
    model: {
      required: true,
    },
    emails: {
      required: true,
    },
    name: {
      required: true,
      type: String,
    },
    sendMailUrl: {
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    onClickCancelInstruction() {
      const self = this;
      this.sweetAlertConfirm(this.$t('order.are_you_sure_about_cancelling_all_the_instruction_from_current_type')).then(response => {
        if (response) {
          self.$emit('onCancelInstruction');
        }
      })
    },
    onClickRepeatRequest() {
      const self = this;
      this.sweetAlertConfirm(this.$t('sample.are_you_sure_repeat_request')).then(response => {
        if (response) {
          self.$emit('onClickRepeatRequest');
        }
      })
    },
    sendMail() {
      let self = this;
      let payloads = {
        url: self.sendMailUrl,
      }

      this.sweetAlertLoading();
      this.$store.dispatch(CREATE_ITEM, payloads).then(response => {
        if (response.status) {
          self.sweetAlertSuccess(self.$t('general.success'));
        } else {
          let result = response.data.response;
          if (result.hasOwnProperty('data') && result.data.hasOwnProperty('message')) {
            self.sweetAlertError(result.data.message);
          } else {
            self.sweetAlertError(result.data);
          }
        }
        self.sweetAlertClose();
      }).catch(response => {
        let result = response.data.response;
        if (result.hasOwnProperty('data') && result.data.hasOwnProperty('message')) {
          self.sweetAlertError(result.data.message);
        } else {
          self.sweetAlertError(result.data);
        }
        self.sweetAlertClose();
      });
    }
  },
  computed: {
    showSubmitButton() {
      if (this.isEditing) {
        return this.isValidToSubmit;
      } else {
        return !this.model.id && this.isValidToSubmit;
      }
    }
  },
}
</script>

<style scoped>

</style>
