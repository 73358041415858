<template>
  <div>
    <div class="d-flex navy-blue-border-top-1px">
      <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
        {{ $t('order.currency_unit') }}
      </div>
      <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
        {{ $t('order.exchange_rate') }}
      </div>
      <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
        {{ $t('order.vade') }}
      </div>
      <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
        {{ $t('order.unit_price') }}
      </div>
      <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
        {{ $t('order.total_price') }}
      </div>
      <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
        {{ $t('order.incoming_invoice_amount') }}
      </div>
      <div class="flex-1 py-2 text-center">
        {{ $t('order.remaining_invoice_amount') }}
      </div>
    </div>
    <div class="d-flex navy-blue-border-top-1px">
      <div class="flex-1 py-2 d-flex flex-column items-center justify-content-center text-center navy-blue-border-right-1px">
        <custom-multi-select
            :options="currencyOptions"
            :title="null"
            :item-per-row="2"
            :model.sync="model.currency_unit_id"
            :name="`currency_unit_id_${uid}`"
            :max="1"
            :not-list="true"
            :required="false"
            size="sm"
            :has-bottom-margin="false"
        ></custom-multi-select>
      </div>
      <div class="flex-1 py-2 d-flex flex-column justify-content-center text-center navy-blue-border-right-1px">{{ model.exchange_rate }}</div>
      <div class="flex-1 py-2 d-flex flex-column items-center justify-content-center text-center navy-blue-border-right-1px">
        <custom-multi-select
            :options="expirationTypeOptions"
            :title="null"
            :item-per-row="2"
            :model.sync="model.expiry_id"
            :name="`expiry_${uid}`"
            :max="1"
            object-key="name"
            :not-list="true"
            :required="false"
            size="sm"
            :has-bottom-margin="false"
        ></custom-multi-select>
      </div>
      <div class="flex-1 d-flex flex-column justify-content-center py-2 text-center navy-blue-border-right-1px">
        <div class="px-2">
          <input
              class="form-control form-control-sm"
              type="number"
              step="0.01"
              min="0"
              :placeholder="$t('order.please_enter_unit_price')"
              v-model="model.unit_price"
              :name="`unit_price_${uid}`"
              :required="false"
              :class="{'is-invalid': !+model.unit_price}"
          />
        </div>
      </div>
      <div class="flex-1 d-flex items-center justify-content-center py-2 text-center navy-blue-border-right-1px">
        {{ totalPrice }} {{ currencyOptions[model.currency_unit_id] }}
      </div>
      <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
      </div>
      <div class="flex-1 py-2 text-center"></div>
    </div>
  </div>
</template>

<script>
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import { v4 as uuid } from 'uuid'
export default {
  name: "CurrencyTableRow",
  components: {CustomMultiSelect},
  props: {
    model: {
      required: true,
      type: Object
    },
    currencyOptions: {
      required: true,
    },
    currencyOptionsRaw: {
      required: true,
    },
    expirationTypeOptions: {
      required: true,
    },
    currencyRateOptions: {
      required: true,
    }
  },
  data(){
    return {
      uid: uuid(),
    }
  },
  methods: {
    fixExchangeRate() {
      if (this.model.currency_unit_id in this.currencyOptions) {
        let currentOption = this.currencyOptionsRaw.find(item => item.id == this.model.currency_unit_id);
        this.model.exchange_rate = this.currencyRateOptions[currentOption.code].rate;
      }
    }
  },
  computed: {
    totalPrice() {
      if('total_amount' in this.model) {
        this.model.total_price = (+this.model.total_amount * +this.model.unit_price).toFixed(2);
      }else{
        this.model.total_price = (+this.model.amount * +this.model.unit_price).toFixed(2);
      }
      return this.model.total_price;
    }
  },
  mounted() {
    this.fixExchangeRate();
  },
  watch: {
    'model.currency_unit_id'() {
      this.fixExchangeRate();
    }
  }
}
</script>

<style scoped>

</style>
