import { render, staticRenderFns } from "./ReadMadeAndPaintForm.vue?vue&type=template&id=637ee154&scoped=true&"
import script from "./ReadMadeAndPaintForm.vue?vue&type=script&lang=js&"
export * from "./ReadMadeAndPaintForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "637ee154",
  null
  
)

export default component.exports