<template>
  <div class="px-4">
    <div class="row" :class="{'mt-5': index !== 0}" v-for="(value, index) in modelProxy.detail">
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
        <custom-multi-select
            :min="1"
            :max="1"
            :is-inline="false"
            :options="supplierCompanies"
            :title="$t('sample.supplier_company')"
            :helper-text="$t('sample.supplier_company_helper_text')"
            :placeholder="$t('sample.multi_select')"
            input-width="150px"
            :model.sync="value.supplier_company_id"
            name="supplier_company"
            :not-list="true"
        ></custom-multi-select>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
        <date-picker-input
            :title="$t('sample.flow_date')"
            :helper-text="$t('sample.flow_date_helper_text')"
            :is-inline="false"
            :placeholder="$t('sample.flow_date_helper_text')"
            :name="`yarn_flow_date_${index}`"
            :model.sync="value.flow_date"
        ></date-picker-input>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
        <date-picker-input
            :title="$t('sample.end_date')"
            :is-inline="false"
            :helper-text="$t('sample.end_date_helper_text')"
            :placeholder="$t('sample.end_date_helper_text')"
            :name="`yarn_end_date_${index}`"
            :model.sync="value.end_date"
        ></date-picker-input>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <number-input
            :title="$t('sample.amount')"
            :helper-text="$t(`sample.amount_helper_text`)"
            :model.sync="value.amount"
            :name="`amount_${index}`"
            :is-inline="false"
            :min="0"
            :step="1"
        ></number-input>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <custom-multi-select
            :min="1"
            :max="1"
            :is-inline="false"
            :options="unitOptions"
            :title="$t('sample.unit_name')"
            :helper-text="$t(`sample.amount_helper_text`)"
            :placeholder="$t('sample.multi_select')"
            input-width="150px"
            :model.sync="value.amount_unit_id"
            name="value_amount_unit_id"
            :not-list="true"
        ></custom-multi-select>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <number-input
            :title="$t('sample.price')"
            :helper-text="$t('sample.price_helper_text')"
            :model.sync="value.price"
            :name="`amount_${index}`"
            :is-inline="false"
            :min="0"
            :step="1"
        ></number-input>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <custom-multi-select
            :min="1"
            :max="1"
            :is-inline="false"
            :options="currencyOptions"
            :title="$t('sample.unit_name')"
            :helper-text="$t('sample.price_helper_text')"
            :placeholder="$t('sample.multi_select')"
            input-width="150px"
            :model.sync="value.currency_unit_id"
            name="value_amount_unit_id"
            :not-list="true"
        ></custom-multi-select>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <custom-multi-select
            :min="1"
            :max="1"
            :is-inline="false"
            :options="dispatchLocations"
            :placeholder="$t('sample.multi_select')"
            :title="$t('sample.dispatch_location')"
            input-width="150"
            :helper-text="$t('sample.dispatch_location_helper_text')"
            :model.sync="value.dispatch_location_id"
            :name="`dispatch_location_${index}`"
            :not-list="true"
        ></custom-multi-select>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <custom-multi-select
            :max="1"
            :options="expirationTypeOptions"
            :title="$t('sample.payment_method')"
            :placeholder="$t('sample.multi_select')"
            :model.sync="value.expiry_id"
            :is-inline="false"
            input-width="100"
            object-key="name"
            :item-per-row="2"
            name="expiration_type_options"
            :not-list="true"
            :show-invalid="false"
        ></custom-multi-select>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <text-input
            :title="$t('sample.to_who')"
            :is-inline="false"
            :helper-text="$t('sample.to_who_helper_text')"
            :name="`to_who_${index}`"
            :model.sync="value.to_who"
            :required="false"
        ></text-input>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <custom-multi-select
            @save="onFabricYarnSave"
            :options="yarnTypes"
            :is-inline="false"
            :title="$t('sample.yarn_type')"
            objectKey="translations.0.name"
            :helper-text="$t('sample.yarn_type_helper_text')"
            :placeholder="$t('sample.multi_select')"
            input-width="150"
            :model.sync="value.fabric_yarn_type_id"
            :name="`yarn_type,${index}`"
        ></custom-multi-select>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 row py-2 bg-gray-100 mb-3 border-15px" v-if="value.fabric_yarn_types.length > 0">
        <div class="col-12" v-for="(fabricDetail, i) in value.fabric_yarn_types">
          <number-input
            :title="getValueByDottedKey(yarnTypes[fabricDetail.fabric_yarn_type_id], 'translations.0.name')"
            :name="`fabric_yarn_types_amount_${index}_${i}`"
            :model.sync="fabricDetail.amount"
            :is-valid="isAmountValid[index]"
          ></number-input>
        </div>
      </div>
      <div class="w-100 d-flex  mb-3 px-4" :class="index > 0 ? 'justify-content-between' : 'justify-content-end' ">
        <div v-if="index > 0">
          <button-with-icon
              v-if="index > 0"
              @onClick="deleteItem(index)"
              icon-name="icons.sample.delete_red"
              class="navy-blue-border-1px"
              :text="$t('general.delete')"
          ></button-with-icon>
        </div>
        <button-with-icon
            @onClick="value.showDescription = true"
            icon-name="icons.sample_instruction.add_2"
            class="navy-blue-border-1px"
            :text="$t('sample.add_description')"
        ></button-with-icon>
      </div>
      <div class="pt-5 pb-2 navy-blue-border-1px border-15px mb-4 w-100 mx-4 px-3"
           v-if="value.showDescription || value.description">
        <textarea-input
            :title="null"
            :model.sync="value.description"
            :required="false"
        ></textarea-input>
        <div class="w-50px ">
          <button-with-icon
              @onClick="hideShowDescription(index)"
              icon-name="icons.sample.delete_red"
              class="navy-blue-border-1px"
              :text="$t('general.delete')"
          ></button-with-icon>
        </div>
      </div>

      <div class="bottom-border-1px-navy py-1 w-100 opacity-50"
           v-if="(index !== modelProxy.detail.length - 1)"></div>
    </div>
    <div class="w-100 d-flex justify-content-between mb-1">
      <button-with-icon
          @onClick="addMore"
          icon-name="icons.sample_instruction.add_2"
          class="navy-blue-border-1px"
          :text="$t('sample.add_supplier')"
      ></button-with-icon>
      <button-with-icon
          @onClick="addMore"
          icon-name="icons.sample_instruction.add_2"
          class="navy-blue-border-1px"
          :text="$t('sample.add_dispatch_location')"
      ></button-with-icon>
    </div>
    <div class="w-100 my-7 d-flex justify-content-center">
      <div class="bottom-border-1px-navy mx-auto d-inline-block">
        <span class="d-inline-block text-2xl mr-10 font-weight-bolder">{{ $t('sample.total_price') }}</span>
        <span class="text-2xl">{{ totalPrice }}
          {{ modelProxy.detail[0].currency_unit_id ? currencyOptions[modelProxy.detail[0].currency_unit_id] : ''}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import NumberInput from "@/assets/components/inputs/NumberInput";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import NumberInputWithUnit from "@/assets/components/inputs/NumberInputWithUnit";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import TextInput from "@/assets/components/inputs/TextInput";

export default {
  name: "YarnForm",
  components: {
    TextInput,
    CustomMultiSelect,
    NumberInput,
    DatePickerInput,
    NumberInputWithUnit,
    TextareaInput,
    ButtonWithIcon,
  },
  props: {
    model: {
      required: true,
    },
    supplierCompanies: {
      required: true,
    },
    expirationTypeOptions: {
      required: true,
    },
    expiryId: {
      required: false,
    },
    currencyOptions: {
      required: true,
    },
    yarnTypes: {
      required: true,
    },
    dispatchLocations: {
      required: true,
    },
    unitOptions: {
      required: true,
    },
    currency_unit_id: {
      required: true,
    }
  },
  computed: {
    modelProxy: {
      get(){
        return this.model;
      },
      set(value) {
        this.$emit('update:model', value)
      }
    },
    totalPrice(){
      let total = 0;
      for(let i = 0; i < this.modelProxy.detail.length; i++){
        total += Number(this.modelProxy.detail[i].amount) * Number(this.modelProxy.detail[i].price);
      }
      return total.toFixed(2);
    },
    isAmountValid(){
      let total = [];
      this.model.detail.map((value, index) => {
        total.push(0);
        for(let i = 0; i < value.fabric_yarn_types.length; i++){
          total[index] += Number(value.fabric_yarn_types[i].amount);
        }
        total[index] = total[index] === 100;
      })
      return total;
    }
  },
  methods: {
    addMore(){
      this.modelProxy.detail.push({...this.modelProxy.detail.slice(-1)[0], ...{showDescription: false, description: null} })
    },
    hideShowDescription(index){
      this.modelProxy.detail[index].description = '';
      this.modelProxy.detail[index].showDescription  = false;

    },

    deleteItem(index){
      this.modelProxy.detail.splice(index,1);
    },
    onFabricYarnSave(payload){
      let {name, oldValue, newValue} = payload;
      let index = Number(name.split(',')[1]);

      let currentYarn = this.modelProxy.detail[index];

      for (let i = 0; i < oldValue.length; i++) {
        if (!newValue.includes(oldValue[i])) {
          for (let j = 0; j < currentYarn.fabric_yarn_types.length; j++) {
            if (Number(currentYarn.fabric_yarn_types[j].fabric_yarn_type_id) === Number(this.yarnTypes[oldValue[i]].id)) {
              currentYarn.fabric_yarn_types.splice(j, 1);
              break;
            }
          }
        }
      }
      let n = newValue.length - currentYarn.fabric_yarn_types.length;
      let remaining = 0;
      for (let i = 0; i < currentYarn.fabric_yarn_types.length; i++) {
        remaining += Number(currentYarn.fabric_yarn_types[i].amount);
      }
      let newPercent = 0;
      if ((100 - remaining) !== 0 || n > 0) {
        newPercent = ((100 - remaining) / n).toFixed(0);
      }
      for (let i = 0; i < newValue.length; i++) {
        if (!oldValue.includes(newValue[i])) {
          this.modelProxy.detail[index].fabric_yarn_types.push({
            fabric_yarn_type_id: this.yarnTypes[newValue[i]].id,
            amount: newPercent,
          });
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
