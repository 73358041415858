<template>
  <div v-if="loaded && authUser">
    <div class="col-md-12 px-0">
      <div id="filter-card" class="row justify-content-start items-baseline w-100" style="margin-bottom: -5px;">
        <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)">
          <i class="fa fa-chevron-left"></i>
        </span>
        <h3 class="d-inline-block">{{ $t('editSample.sample_detail') }}</h3>
        <div class="" style="flex-grow: 1">
          <div class="w-100 d-flex flex-row-reverse pl-3 mt-3 mb-3">
            <button-with-icon
                v-if="sample.is_completed"
                :disabled="Number(sample.copied_id) === 0"
                :icon-name="null"
                :text="`1.${$t('editSample.repeat')}`"
                class="ml-3"
                @onClick="Number(sample.copied_id) === 0 ? null : $router.replace({name: 'sample.product.edit', params: {id: sample.copied_id }})"
            >
            </button-with-icon>
            <button-with-icon v-for="(value, index) in sample.copied_ids" v-if="sample.copied_ids.length" :key="index"
                              :active="true"
                              :disabled="Number(id) === Number(value)"
                              :icon-name="null"
                              :text="`${index + 2}.${$t('editSample.repeat')}`"
                              class="ml-3"
                              @onClick="$router.replace({name: 'sample.product.edit', params: {id: sample.copied_ids[index]}})"
            ></button-with-icon>

            <button-with-icon
                :active="true"
                :icon-name="null"
                :text="$t('editSample.instructions')"
                class="ml-3"
                @onClick="showInstructions"
            ></button-with-icon>

          </div>

        </div>
      </div>
    </div>
    <modal :adaptive="true" height="auto" name="sample-note-modal" width="750">
      <div class="card card-custom">
        <div class="card-body card-body-scroll d-flex justify-content-center mt-5">
          <div class="col-12">
            <textarea-input
                :is-inline="false"
                :model.sync="tempNote"
                :required="false"
                :title="$t('editSample.note')"
            ></textarea-input>
          </div>
        </div>
        <div class="card-footer d-flex justify-content-end">
          <button class="btn btn-sm btn-danger font-weight-bold cursor-pointer mr-3"
                  type="button"
                  @click="closeModalAndCancel('sample-note-modal')">
            {{ $t('editSample.close').toUpperCase() }}
          </button>
          <button class="btn btn-sm btn-primary font-weight-bold cursor-pointer mr-3"
                  type="button"
                  @click="closeModalAndSave('sample-note-modal')">
            {{ $t('editSample.save').toUpperCase() }}
          </button>
        </div>
      </div>
    </modal>
    <modal :adaptive="true" height="auto" name="open-qr-code-modal" width="750">
      <div class="card card-custom">
        <div class="card-body card-body-scroll d-flex justify-content-center mt-5">
          <q-r-code
              :code="sample.code" :font-classes="'text-lg font-weight-boldest'" :size="300"
              :value="sample.code_link"
              level="H"
          ></q-r-code>
        </div>
        <div class="card-footer d-flex justify-content-end">
          <button class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3"
                  type="button"
                  @click="closeModalByName('open-qr-code-modal')">{{ $t('editSample.close') }}
          </button>
        </div>
      </div>
    </modal>

    <b-modal ref="instructions-modal" id="instructions-modal" hide-footer hide-header hide-header-close size="xl" scrollable>
      <instructions-modal
          :instruction-types="instructionTypes"
          :items="instructions"
          :units="unitOptions2"
          @onCloseModal="onCloseModal"
      />
    </b-modal>
    <div class="card card-custom">
      <div class="card-body relative">
        <div class="d-flex justify-content-end container-fluid">
          <div class="d-lg-flex d-md-flex flex-column justify-content-center items-center absolute d-sm-none"
               style="min-width: 150px;">
            <img
                :src="sample.default_content_id == null ? sample.image : convertArrayToObjectByKey(productContent, 'id')[sample.default_content_id] ? convertArrayToObjectByKey(productContent, 'id')[sample.default_content_id].file_link : sample.image"
                accept="image/*" alt=""
                class="w-50px h-50px rounded-sm cursor-pointer my-2 gray-border-2px"
                @click="openSelectImageForSampleModal">
            <span class="text-center font-weight-bold" v-html="$t('editSample.current_sample_image')"></span>
            <div v-if="Number(sample.is_completed)" class="cursor-pointer mt-3"
                 @click="$modal.show('open-qr-code-modal')">
              <q-r-code
                  :code="sample.code" :size="50" :value="sample.code_link"
                  level="H"
              ></q-r-code>
            </div>
          </div>
        </div>
        <!--begin: Wizard-->
        <modal
            :adaptive="true"
            height="auto"
            name="select-image-for-sample"
            width="750"
        >
          <sample-image-selector-modal
              :images="productContentByType(3)"
              @onSubmit="onSampleImageSubmitted"
          >
          </sample-image-selector-modal>
        </modal>
        <template v-if="loaded">
          <form action="" @submit.prevent="submit">
            <h3 v-b-toggle.information_defined_by_system class="cursor-pointer mt-n3 d-flex justify-content-center"
                variant="info">
              <div class="text-underline-gray">
              <span v-html="getIconByKey('icons.sample.system-description', {})"
              ></span>
                <span>{{ $t('editSample.information_defined_by_system') }}</span>
                <i class="fas fa-caret-down ml-3"></i>
              </div>
            </h3>
            <b-collapse id="information_defined_by_system" class="row mt-5 py-5" visible>
              <div class="row w-100 px-5">
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" v-if="modelHouseId && isUserGranted('Staff', ['modelHouseChief'],false)">
                  <div class="col-lg-12 col-md-12 col-sm-12 d-flex">
                  <button class="btn btn-sm btn-success" type="button" @click="markAsCompleted" style="color:#ffffff">
                    <i class="fas fa-check"></i>
                    {{$t("model_house.mark_as_completed") }}
                  </button>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                  <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
                    <p class="font-weight-boldest flex-40">{{ $t('editSample.product_name') }}</p>
                    <p class="flex-60"> : {{ sample.name }}</p>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                  <div class="col-lg-12 col-md-12 col-sm-12 d-flex">
                    <p class="font-weight-boldest flex-40">{{ $t('editSample.category') }}</p>
                    <p class="flex-60"> : {{ sample.sample_type.translations[0].name }}</p>
                  </div>
                </div>
                <div class="col-xl-2 col-lg-4 col-md-6 col-sm-12">
                  <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
                    <p class="font-weight-boldest flex-40">{{ $t('editSample.order_date') }}</p>
                    <p class="flex-60"> : {{ sample.order_date | momentDateFormat }}</p>
                  </div>
                </div>
                <div v-if="meeting" class="col-xl-4 col-lg-4 col-md-6 col-sm-12 float-left">
                  <div v-if="meeting != null" class="col-lg-12 col-md-12 col-sm-12 d-flex">
                    <p class="font-weight-boldest flex-40">{{ $t('editSample.meeting_number') }}</p>
                    <p class="flex-60"> : T{{ addZerosToId(Number(sample.meeting_id)) }}</p>
                  </div>
                </div>
                <div v-if="meeting" class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                  <div v-if="meeting != null" class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
                    <p class="font-weight-boldest flex-40">{{ $t('editSample.meeting_name') }}</p>
                    <p class="flex-60"> : {{ meeting.name }}</p>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                  <div class="col-lg-12 col-md-12 col-sm-12 d-flex">
                    <p class="font-weight-boldest flex-40">{{ $t('editSample.number') }}</p>
                    <p class="flex-60"> : {{ sample.collection_number }}</p>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                  <div class="col-lg-12 col-md-12 col-sm-12 d-flex">
                    <p class="font-weight-boldest flex-40">{{ $t('editSample.client_name') }}</p>
                    <p class="flex-60"> : {{ sample.company.name }}</p>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                  <div class="col-lg-12 col-md-12 col-sm-12 d-flex">
                    <p class="font-weight-boldest flex-40">{{ $t('editSample.completed_at') }}</p>
                    <p class="flex-60"> : <span
                        v-if="sample.completed_at">{{ sample.completed_at| momentDateFormat }}</span></p>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                  <div class="col-lg-12 col-md-12 col-sm-12 d-flex">
                    <select-filter :helper-text="$t('sample.status_by_filter')"
                    :model.sync="sample.sample_status_id" :values="sampleStatuses"></select-filter>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12"></div>
              </div>
            </b-collapse>

            <h3 v-b-toggle.meeting_note_or_product_description
                class="cursor-pointer pb-2 mb-5 d-flex justify-content-center"
            >
              <div class="text-underline-gray">
              <span
                  v-html="getIconByKey(Number(sample.meeting_id) === 0 ? 'icons.sample.collection-description' : 'icons.sample.meeting-note', {})"></span>
                <span>
                {{
                    Number(sample.meeting_id) === 0 ? $t('editSample.collection_description') : $t('editSample.meeting_notes')
                  }}</span>
                <i class="fas fa-caret-down ml-3"></i>
              </div>
            </h3>
            <b-collapse id="meeting_note_or_product_description" class="row mt-5 py-5" visible>

              <div class="px-5 w-100">
                <b-row>
                  <template v-for="content in productContent"
                            v-if="productContent !== null && productContent.length !== 0 ">
                    <template v-if="Number(content.content_type_id) === 1">
                      <b-col class="mt-2" md="6" xl="4">
                        <meeting-text-holder-card :content="content"
                                                  :is-sharable="true"
                                                  :is-deletable="Number(content.user_id) === Number(authUser.user_id)"
                                                  @onClickDelete="deleteContentById"
                        ></meeting-text-holder-card>
                      </b-col>
                    </template>

                    <template v-if="Number(content.content_type_id) === 2">
                      <b-col class="mt-2" md="6" xl="4">
                        <meeting-video-holder-card 
                              :content="content"
                              :is-sharable="true"
                              :is-deletable="Number(content.user_id) === Number(authUser.user_id)"
                              @onClickDelete="deleteContentById"
                        ></meeting-video-holder-card>
                      </b-col>
                    </template>

                    <template v-if="Number(content.content_type_id) === 3">
                      <b-col class="mt-2" md="6" xl="4">
                        <meeting-image-holder-card :content="content"
                                                   :is-deletable="Number(content.user_id) === Number(authUser.user_id)"
                                                   @onClickDelete="deleteContentById"
                        ></meeting-image-holder-card>
                      </b-col>
                    </template>

                    <template v-if="Number(content.content_type_id) === 4">
                      <b-col class="mt-2" md="6" xl="4">
                        <meeting-audio-player-holder-card :content="content"
                                                          :is-sharable="true"
                                                          :is-deletable="Number(content.user_id) === Number(authUser.user_id)"
                                                          @onClickDelete="deleteContentById"
                        ></meeting-audio-player-holder-card>
                      </b-col>
                    </template>
                  </template>
                </b-row>

                <template v-if="productContent === null || productContent.length === 0">
                  <h5 class="text-center mb-lg-10">{{ $t('general.there_is_content_available') }}</h5>
                </template>
                <div class="d-flex justify-content-center">
                  <modal :adaptive="true" height="auto" name="create-new-image-content-form-modal" width="750">
                    <div class="card card-custom">
                      <div class="card-body card-body-scroll">
                        <photo-recorder :model.sync="newProductContent"></photo-recorder>
                        <file-input
                            ref="imageFileInput"
                            :model.sync="newProductContent"
                            :title="$t('editSample.image')"
                            accept="image/*"
                            name="content-image"
                            @fileChange="onNewImageSelected"
                        >
                          <template v-slot:inputDesign>
                            <div class="w-100 d-flex justify-content-center"
                                 @click="$refs.imageFileInput.openFileInput()"
                            >
                              <span v-html="getIconByKey('icons.meeting.select-from-file', {
                                    class: 'w-75px h-75px d-inline-block object-fill cursor-pointer'})"
                              >
                            </span>
                            </div>
                          </template>
                        </file-input>
                      </div>
                      <div class="card-footer d-flex justify-content-end">
                        <button class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3"
                                type="button"
                                @click="closeModalByName('create-new-image-content-form-modal')">
                          {{ $t('editSample.close') }}
                        </button>
                        <button :disabled="is_submitting"
                                class="btn btn-sm btn-primary font-weight-bold cursor-pointer"
                                type="button"
                                @click="uploadContent(3, 'create-new-image-content-form-modal')">{{
                            $t('editSample.save')
                          }}
                        </button>
                      </div>
                    </div>
                  </modal>
                  <span :disabled="isInCopyState || modelHouseTaskStatus" @click="openCreateNewImageContentFormModal"
                        v-html="getIconByKey('icons.meeting.add-image', {
                  class: 'w-50px h-50px d-inline-block object-fill cursor-pointer'
                })">
                </span>
                  <modal :adaptive="true" height="auto" name="create-new-video-content-form-modal" width="750">
                    <div class="card card-custom">
                      <div class="card-header py-7">
                        <h3>{{ $t("editSample.please_select_video_to_upload") }}</h3>
                      </div>
                      <div class="card-body card-body-scroll">
                        <video-recorder :model.sync="newProductContent"></video-recorder>
                        <file-input
                            ref="videoFileInput"
                            :model.sync="newProductContent"
                            :title="$t('editSample.video')"
                            accept="video/*"
                            name="content-video"
                        >
                          <template v-slot:inputDesign>
                            <div class="w-100 d-flex justify-content-center"
                                 @click="$refs.videoFileInput.openFileInput()"
                            >
                              <span
                                  v-html="getIconByKey('icons.meeting.select-from-file', {
                                    class: 'w-75px h-75px d-inline-block object-fill cursor-pointer',
                                  })"
                              >
                            </span>
                            </div>
                          </template>
                        </file-input>
                      </div>
                      <div class="card-footer d-flex justify-content-end">
                        <button
                            class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3"
                            type="button"
                            @click="closeModalByName('create-new-video-content-form-modal')">
                          {{ $t('editSample.close') }}
                        </button>
                        <button
                            ref="submitVideo"
                            :disabled="is_submitting"
                            class="btn btn-sm btn-primary font-weight-bold cursor-pointer"
                            type="button"
                            @click="uploadContent(2, 'create-new-video-content-form-modal')">{{ $t('editSample.save') }}
                        </button>
                      </div>
                    </div>
                  </modal>
                  <span :disabled="isInCopyState" @click="openCreateNewVideoContentFormModal" v-html="getIconByKey('icons.sample.add-video', {
                  class: 'w-50px h-50px d-inline-block object-fill cursor-pointer'
                })">
                </span>
                  <modal
                      :adaptive="true"
                      height="auto"
                      name="create-new-audio-content-form-modal"
                      width="750"
                  >
                    <div class="card card-custom">
                      <div class="card-body card-body-scroll">
                        <audio-recorder :model.sync="newProductContent"></audio-recorder>
                        <file-input
                            ref="videoFileInput"
                            :model.sync="newProductContent"
                            :title="$t('editSample.image')"
                            accept="audio/*"
                            name="content-image"
                            @fileChange="onNewImageSelected"
                        >
                          <template v-slot:inputDesign>
                            <div class="w-100 d-flex justify-content-center"
                                 @click="$refs.videoFileInput.openFileInput()"
                            >
                              <span
                                  v-html="getIconByKey('icons.meeting.select-from-file', {
                                    class: 'w-75px h-75px d-inline-block object-fill cursor-pointer',
                                  })"
                              >
                            </span>
                            </div>
                          </template>
                        </file-input>
                      </div>
                      <div class="card-footer d-flex justify-content-end">
                        <button class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3"
                                type="button"
                                @click="closeModalByName('create-new-audio-content-form-modal')">
                          {{ $t('editSample.close') }}
                        </button>
                        <button :disabled="is_submitting"
                                class="btn btn-sm btn-primary font-weight-bold cursor-pointer"
                                type="button"
                                @click="uploadContent(4, 'create-new-audio-content-form-modal')">{{
                            $t('editSample.save')
                          }}
                        </button>
                      </div>
                    </div>
                  </modal>
                  <span :disabled="isInCopyState" @click="openCreateNewAudioContentFormModal" v-html="getIconByKey('icons.sample.add-audio', {
                  class: 'w-50px h-50px d-inline-block object-fill cursor-pointer'
                })">
                </span>
                  <modal
                      :adaptive="true"
                      height="auto"
                      name="create-new-text-content-form-modal"
                      width="750"
                  >
                    <div class="card card-custom">
                      <div class="card-header py-7">
                        <h3>{{ $t("editSample.please_enter_note") }}</h3>
                      </div>
                      <div class="card-body card-body-scroll">
                        <textarea-input
                            :model.sync="newProductContent"
                            :placeholder="$t('editSample.please_enter_note')"
                            :title="$t('editSample.note')"
                            name="product-note"
                        ></textarea-input>
                      </div>
                      <div class="card-footer d-flex justify-content-end">
                        <button class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3"
                                type="button"
                                @click="closeModalByName('create-new-text-content-form-modal')">
                          {{ $t('editSample.close') }}
                        </button>
                        <button :disabled="is_submitting"
                                class="btn btn-sm btn-primary font-weight-bold cursor-pointer"
                                type="button"
                                @click="uploadContent(1, 'create-new-text-content-form-modal')">{{
                            $t('editSample.save')
                          }}
                        </button>
                      </div>
                    </div>
                  </modal>
                  <span :disabled="isInCopyState" @click="openCreateNewTextContentFormModal" v-html="getIconByKey('icons.meeting.add-note', {
                  class: 'w-50px h-50px d-inline-block object-fill cursor-pointer'
                })">
                </span>
                </div>
              </div>
            </b-collapse>
            <h3
                v-b-toggle.information_entry
                class="cursor-pointer pb-2 mb-5 d-flex justify-content-center"
            >
              <div class="text-underline-gray">
              <span v-html="getIconByKey('icons.sample.info-entry', {})"
              ></span>
                <span>{{ $t('editSample.information_entry') }}</span>
                <i class="fas fa-caret-down ml-3"></i>
              </div>
            </h3>
            <b-collapse id="information_entry" class="row pt-5" visible>
              <div class="w-100 px-5">
                <h6 class="text-success mt-5 text-center">*
                  {{ $t('editSample.please_select_image_for_sample_beforehand') }}
                  <button class="btn btn-outline-success btn-sm" type="button"
                          @click.prevent="openSelectImageForSampleModal">
                    {{ $t("editSample.select") }}
                  </button>
                </h6>
                <h4 class="text-center text-success font-weight-light mb-5">
                <span v-html="getIconByKey('icons.sample.sample-responsible-person', {})"
                ></span>
                  {{ $t('editSample.responsible_person_for_current_sample') }} -
                  <b class="font-weight-bolder">{{ sample.users[0].name }}</b>
                </h4>
                <h5 class="cursor-pointer pb-2 mb-1 d-flex justify-content-start">
                  <div class="text-underline-gray">
                  <span>
                    <span v-html="getIconByKey('icons.sample.product', {})"
                    ></span>
                    {{ $t('editSample.collection_description') }}
                  </span>
                  </div>
                </h5>
                <div v-if="!modelHouseTaskStatus" class="d-flex justify-content-center">
                  <button-with-icon
                      :text="$t('general.scan_qr_code').toUpperCase()"
                      class="text-navy-blue navy-blue-border-1px font-weight-boldest"
                      icon-name="icons.sample.scan-qr-code"
                      size="lg"
                  ></button-with-icon>
                </div>
                <div id="collection_description" class="row pt-5">
                  <div v-if="Number(sample.sample_type_id) === 1" class="col-lg-2 col-md-6 col-sm-12">
                    <text-input
                        :disabled="modelHouseTaskStatus"
                        :helper-text="$t('editSample.please_enter_model_no')"
                        :is-valid="sample.model_number"
                        :model.sync="sample.model_number"
                        :placeholder="$t('editSample.please_enter_model_no')"
                        :title="$t('editSample.model_no')"
                        name="model_no"
                    ></text-input>
                  </div>
                  <div class="col-lg-2 col-md-6 col-sm-12">
                    <text-input
                        :required="false"
                        :disabled="modelHouseTaskStatus"
                        :model.sync="sample.to_who"
                        :placeholder="$t('editSample.please_enter_owner_of_the_product')"
                        :title="$t('editSample.product_for')"
                        name="product_for"
                    ></text-input>
                  </div>
                  <div class="col-lg-2 col-md-6 col-sm-12">
                    <date-picker-input
                        :disabled="modelHouseTaskStatus"
                        :is-valid="sample.deadline"
                        :model.sync="sample.deadline"
                        :title="$t('editSample.deadline')"
                        name="to_who"
                    ></date-picker-input>
                  </div>
                  <div v-if="Number(sample.sample_type_id) === 1"
                       class="col-lg-3 col-md-6 col-sm-12"
                  >
                    <text-input
                        :disabled="modelHouseTaskStatus"
                        :helper-text="$t('editSample.please_enter_model_name')"
                        :is-valid="modelNameValidation ? true : null"
                        :model.sync="sample.model_name"
                        :placeholder="$t('editSample.please_enter_model_name')"
                        :title="$t('editSample.model_name')"
                        name="model_name"
                    ></text-input>
                  </div>
                  <div class="col-lg-2 col-md-6 col-sm-12">
                    <custom-multi-select
                        :disabled="modelHouseTaskStatus"
                        :max="1"
                        :model.sync="sample.product_model_id"
                        :not-list="true"
                        :options="productModelOptions"
                        :placeholder="$t('editSample.please_select_model_description')"
                        :required="sample.sample_type_id===1"
                        :show-invalid="sample.sample_type_id===1"
                        :title="$t('editSample.model_description')"
                        name="model_description"
                        object-key="translations.0.name"
                    >
                    </custom-multi-select>
                  </div>
                </div>
                <h5
                    v-if="Number(sample.sample_type_id) !== 3 && !accessoryRequestId"
                    class="cursor-pointer pb-2 mb-5 d-flex justify-content-start"
                >
                  <div class="text-underline-gray">
                  <span>
                    <span v-html="getIconByKey('icons.sample.color-and-size-description', {})"
                    ></span>
                    {{ $t('editSample.color_and_size_description') }}
                  </span>
                  </div>
                </h5>
                <div v-if="Number(sample.sample_type_id) !== 3 && !accessoryRequestId"
                     id="color_and_size" class="row pt-5">
                  <template v-if="Number(sample.sample_type_id) === 1">
                    <div class="col-12 text-lg d-flex justify-content-end">
                      <span class="cursor-pointer" @click="addNewSizeAndColor" v-html="getIconByKey('icons.waybill.waybill_entry',{
                        class: 'w-35px h-35px d-inline-block mr-3'
                      })"></span>
                      <span class="cursor-pointer" @click="deleteNewSizeAndColor" v-html="getIconByKey('icons.sample.delete_red',{
                        class: 'w-20px h-20px d-inline-block mr-3 '
                      })"></span>
                    </div>
                    <div class="w-100 row navy-blue-border-1px mx-3 border-15px" style="overflow-x: auto;">
                      <div class="col-12 row mx-0 navy-blue-border-bottom-1px">
                        <div class="w-250px"></div>
                        <div class="flex-grow-1 text-center text-lg font-weight-bold py-1"
                             style="min-width: 150px !important; max-width: calc(100% - 300px);">
                          {{ $t('order.sizes').toUpperCase() }}
                        </div>
                      </div>
                      <div v-for="(size_and_color, index, key) in sample.sample_variations"
                           :key="index"
                           :class="{
                            'navy-blue-border-top-1px': sample.sample_variations.length > 1,

                           }" class="col-12 row mx-0 pb-0 pt-0  ">
                        <div class="w-150px row mx-0 navy-blue-border-right-1px pb-1">
                          <div v-if="index === sample.sample_variations.length - 1"
                               class="col-12 d-flex justify-content-end mt-1">
                          </div>

                          <div class="col-12 text-lg font-weight-bold py-0 my-0">
                            <div class="d-flex justify-content-between items-start">
                              <span class="d-inline-block">{{ $t('editSample.color') }}</span>
                              <span v-if="index > 0 " class="cursor-pointer justify-content-end d-inline-block mb-2"
                                    @click="deleteNewSizeAndColor(index)" v-html="getIconByKey('icons.sample.delete_red',{
                        class: 'w-20px h-20px d-inline-block mr-3 '
                      })"></span>
                            </div>
                            <input v-model="size_and_color.color" :placeholder="$t('editSample.color')"
                                   class="form-control form-control-sm"
                                   step="1" type="text">
                          </div>
                          <div class="col-12 text-lg font-weight-bold py-0 my-0">
                            <div>{{ $t('editSample.number_color') }}</div>
                            <input v-model="size_and_color.color_number" :placeholder="$t('editSample.number_color')"
                                   class="form-control form-control-sm"
                                   step="1" type="number">
                          </div>
                        </div>
                        <div class="flex-grow-1 mx-0"
                             style="min-width: 150px !important; max-width: calc(100% - 250px);">
                          <div class="table-responsive">
                            <table class="table">
                              <thead>
                              <tr>
                                <th v-for="(value, key) in size_and_color.body_sizes" :key="index + '_' + key"
                                    class="text-center navy-blue-border-bottom-1px navy-blue-border-right-1px">
                                  <div class="w-100 d-flex justify-content-center items-center">
                                    <span class="d-inline-block">{{ value.name }}</span>
                                  </div>
                                </th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr class="h-100">
                                <td v-for="(value, key) in size_and_color.body_sizes" :key="index + '_' + key"
                                    class="navy-blue-border-right-1px">
                                  <div class="d-flex justify-content-center">
                                    <input v-model="value.amount"
                                           :class="{'is-invalid': false}"
                                           class="form-control form-control-sm"
                                           type="number">
                                  </div>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="w-100px row mx-0 py-3 ">
                          <div class="col-12 text-lg font-weight-bold">
                            <div class="mb-3">{{ $t('order.total').toUpperCase() }}</div>
                            <h3 class="text-center py-3">{{ sizeAndColorTotalByIndex(index) }}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-for="(colorAndSize, i) in sample.sample_variations"
                            v-if="Number(sample.sample_type_id) !== 1">
                    <div
                        :class="{
                            'col-lg-3 col-md-6 col-sm-12': Number(sample.sample_type_id) === 1,
                            'col-lg-4 col-md-4 col-sm-12': Number(sample.sample_type_id) !== 1
                           }"
                        class="p-0 m-0"
                    >
                      <text-input
                          :disabled="modelHouseTaskStatus"
                          :is-valid="sizeAndColorValidation ? true : null"
                          :model.sync="colorAndSize.color"
                          :placeholder="$t('editSample.please_enter_color_name')"
                          :title="$t('editSample.color')"
                          name="color"
                      ></text-input>
                    </div>
                    <div :class="{
                            'col-lg-3 col-md-6 col-sm-12': Number(sample.sample_type_id) === 1,
                            'col-lg-4 col-md-4 col-sm-12': Number(sample.sample_type_id) !== 1
                           }"
                         class="col-sm-12 m-0 p-0"
                    >
                      <number-input
                          :disabled="modelHouseTaskStatus"
                          :is-valid="sizeAndColorValidation ? true : null"
                          :model.sync="colorAndSize.color_number"
                          :placeholder="$t('editSample.please_enter_product_number_color')"
                          :title="$t('editSample.number_color')"
                          name="color_number"
                          step="1"
                      ></number-input>
                    </div>
                    <div v-if="Number(sample.sample_type_id) === 1"
                         class="col-lg-3 col-md-6 col-sm-12 m-0 p-0">
                      <custom-multi-select
                          :disabled="modelHouseTaskStatus"
                          :max="1"
                          :min="1"
                          :model.sync="colorAndSize.body_size_id"
                          :not-list="true"
                          :options="bodySizeOptions"
                          :placeholder="$t('editSample.please_select_size')"
                          :title="$t('editSample.size')"
                          name="modelDescriptionOptions"
                          object-key="translations.0.name"
                      >
                      </custom-multi-select>
                    </div>
                    <div
                        :class="{
                            'col-lg-3 col-md-6 col-sm-12': Number(sample.sample_type_id) === 1,
                            'col-lg-4 col-md-4 col-sm-12': Number(sample.sample_type_id) !== 1
                           }"
                        class="row m-0 p-0">
                      <div
                          :class="{
                            'col-lg-9 col-md-9 col-sm-9': Number(sample.sample_type_id) === 1 || sample.sample_variations.length > 1,
                            'col-lg-12 col-md-12 col-sm-12': Number(sample.sample_type_id) !== 1 || sample.sample_variations.length <= 1
                           }"
                      >
                        <number-input
                            :disabled="modelHouseTaskStatus"
                            :is-valid="sizeAndColorValidation ? true : null"
                            :min="1"
                            :model.sync="colorAndSize.amount"
                            :placeholder="$t('editSample.please_enter_amount')"
                            :step="1"
                            :title="$t('editSample.amount')"
                            name="amount"
                        ></number-input>
                      </div>
                      <div v-if="Number(sample.sample_type_id) === 1 && sample.sample_variations.length > 1"
                           class="col-lg-3 col-md-3 col-sm-3">
                        <button :disabled="!isColorAndSizeDeletable(i)" class="btn btn-light-danger w-70 btn-sm"
                                type="button"
                                @click="deleteColorAndSizeByIndex(i)">{{ $t('editSample.delete') }}
                        </button>
                      </div>
                    </div>
                  </template>
                </div>
                <h5 v-if="Number(sample.sample_type_id) !== 3" class="pb-2 mt-5 mb-5 d-flex justify-content-start">
                  <div class="text-underline-gray">
                  <span>
                    <span v-html="getIconByKey('icons.sample.product-description', {})"
                    ></span>
                    {{ $t('editSample.product_description') }}  </span>
                  </div>
                </h5>
                <div v-if="Number(sample.sample_type_id) !== 3" class="row pt-5">
                  <h4 v-if="!sample.sample_product_informations" class="text-center col-12">
                    {{ $t('sample.no_product_description') }}</h4>
                  <div v-for="(sample_fabric, index) in sample.sample_product_informations"
                       v-if="sample && sample.sample_product_informations"
                       :key="index" class="col-12 row px-0 mx-0">
                    <div
                        v-if="(requestType && sample_fabric.hasOwnProperty('fabric_request') && sample_fabric.fabric_request && Number(sample_fabric.fabric_request.id) === Number(fabricRequestId)) || !requestType"
                        class="col-lg-3 col-md-3 col-sm-12 d-flex justify-content-start">

                      <span class="pt-2 px-1 w-60px">
                        <i role="button" class="fa fa-file-pdf cursor-pointer mr-1" @click="openInNewTab(sample_fabric.sample_pdf_tag_link)"></i>
                        <i class="fa fa-eye"
                           role="button"
                           @click.prevent="showWaybills(sample_fabric.id, 1, getValueByDottedKey(fabricTypeOptions[sample_fabric.fabric_type_id], 'translations.0.name')  + ' (' +sample_fabric.color + ')')">
                        </i>
                        {{ index + 1 }}-
                       </span>
                      <custom-multi-select
                          :disabled="modelHouseTaskStatus && sample_fabric.id"
                          :max="1"
                          :model.sync="sample_fabric.fabric_type_id"
                          :not-list="true"
                          :options="fabricTypeOptions"
                          :placeholder="$t('editSample.please_select_fabric_type')"
                          :title="$t('editSample.fabric_type')"
                          name="fabric_type"
                          object-key="translations.0.name"
                      >
                      </custom-multi-select>
                    </div>
                    <div
                        v-show="(requestType && sample_fabric.hasOwnProperty('fabric_request') && sample_fabric.fabric_request && Number(sample_fabric.fabric_request.id) === Number(fabricRequestId)) || !requestType"
                        class="col-lg-3 col-md-3 col-sm-12">
                      <text-input
                          :disabled="modelHouseTaskStatus && sample_fabric.id"
                          :is-valid="sizeAndColorValidation ? true : null"
                          :model.sync="sample_fabric.color"
                          :placeholder="$t('editSample.please_enter_color_name')"
                          :title="$t('editSample.color')"
                          name="fabricColor"
                      ></text-input>

                    </div>
                    <div
                        v-show="(requestType && sample_fabric.hasOwnProperty('fabric_request') && sample_fabric.fabric_request && Number(sample_fabric.fabric_request.id) === Number(fabricRequestId)) || !requestType"
                        class="col-lg-3 col-md-3 col-sm-12">
                      <number-input
                          :disabled="modelHouseTaskStatus && sample_fabric.id"
                          :is-valid="sizeAndColorValidation ? true : null"
                          :model.sync="sample_fabric.color_number"
                          :placeholder="$t('editSample.please_enter_product_number_color')"
                          :title="$t('editSample.number_color')"
                          input-max-width="50"
                          name="color_number"
                          step="1"
                      ></number-input>
                    </div>

                    <div
                        v-show="(requestType && sample_fabric.hasOwnProperty('fabric_request') && sample_fabric.fabric_request && Number(sample_fabric.fabric_request.id) === Number(fabricRequestId)) || !requestType"
                        :class="{
                            'col-lg-3 col-md-2 col-sm-12': Number(sample.sample_product_informations_length) === 1,
                            'col-lg-2 col-md-2 col-sm-12': Number(sample.sample_product_informations_length) !== 1
                           }"
                        class="p-0 m-0">
                      <number-input
                          :disabled="modelHouseTaskStatus && sample_fabric.id"
                          :model.sync="sample_fabric.width"
                          :placeholder="$t('editSample.please_enter_width')"
                          :step="1"
                          :title="$t('editSample.width')"
                          name="width"
                      ></number-input>
                    </div>
                    <div v-if="Number(sample.sample_product_informations_length) > 1"
                         class='col-lg-1 col-md-1 col-sm-12'>
                         <span class="cursor-pointer" :disabled="sample.is_completed === 1 && sample_fabric.id" @click="deleteSampleProductInformationIndex(index)" v-html="getIconByKey('icons.sample.delete_red',{
                          class: 'w-20px h-20px d-inline-block mr-3 '
                          })"></span>
                    </div>
                    <div
                        v-show="(requestType && sample_fabric.hasOwnProperty('fabric_request') && sample_fabric.fabric_request && Number(sample_fabric.fabric_request.id) === Number(fabricRequestId)) || !requestType"
                        class="col-lg-3 col-md-3 col-sm-12">
                      <number-input
                          :disabled="modelHouseTaskStatus && sample_fabric.id"
                          :model.sync="sample_fabric.weight"
                          :placeholder="$t('editSample.please_enter_weight')"
                          :step="1"
                          :title="$t('editSample.weight')"
                          name="weight"
                      ></number-input>
                    </div>
                    <div
                        v-show="(requestType && sample_fabric.hasOwnProperty('fabric_request') && sample_fabric.fabric_request && Number(sample_fabric.fabric_request.id) === Number(fabricRequestId)) || !requestType"
                        class="col-lg-4 col-md-4 col-sm-12">
                      <custom-multi-select
                          :disabled="modelHouseTaskStatus"
                          :model.sync="sample_fabric.selectedCompositions"
                          :name="index"
                          :options="fabricCompositionOptions"
                          :placeholder="$t('editSample.please_enter_fabric_composition')"
                          :title="$t('editSample.fabric_composition')"
                          object-key="translations.0.name"
                          width-class="w-50"
                          @save="onFabricCompositionsChange"
                      >
                      </custom-multi-select>
                    </div>
                    <div v-if="sample_fabric.selectedCompositions.length !== 0"
                         v-show="(requestType && sample_fabric.hasOwnProperty('fabric_request') && sample_fabric.fabric_request && Number(sample_fabric.fabric_request.id) === Number(fabricRequestId)) || !requestType"
                         class="col-lg-3 col-md-5 col-sm-12 row px-5 py-3 bg-gray-200 m-0 mb-5">
                      <template v-for="(value, key) in sample_fabric.fabric_composition_sample_product_informations">
                        <div class="col-xl-10 col-lg-10 col-md-12 col-sm-10 d-flex justify-content-start">
                          <span class="pt-2 px-1 w-30px">{{ key + 1 }}-</span>
                          <number-input
                              :disabled="modelHouseTaskStatus && sample_fabric.id"
                              :helper-text="$t('editSample.please_determine_the_value_you_want_the_relevant</br>_percentage_of_fabric_to_have_in_the_composition')"
                              :is-inline="true"
                              :model.sync="sample_fabric.fabric_composition_sample_product_informations[key].percent"
                              :title="getValueByDottedKey(fabricCompositionOptions[sample_fabric.selectedCompositions[key]], 'translations.0.name')"
                          ></number-input>
                          <span v-if="!sample_fabric.id && key > 0"
                                class="cursor-pointer  justify-content-end d-inline-block mb-2"
                                @click="deleteCompositions(index, key)" v-html="getIconByKey('icons.sample.delete_red',
                                {class: 'w-15px h-15px d-inline-block mr-3'})"></span>
                        </div>
                      </template>
                    </div>

                    <div
                        v-if="sample.is_completed && !requestType && !modelHouseTaskStatus && sample_fabric.id && Number(sample.sample_status_id) !== 3"
                        class="col-12 d-flex justify-content-center mt-3 mb-5">
                      <button-with-icon v-if="accessRequestFabric"
                          :disabled="is_submitting"
                          :text="+sample_fabric.is_request === 1 ? $t('sample.request_fabric_again').toUpperCase() : $t('sample.request_fabric').toUpperCase()"
                          class="font-weight-boldest"
                          icon-name="icons.sample.request_fabric"
                          size="lg"
                          @onClick="onClickRequestFabric(index)"
                      ></button-with-icon>
                    </div>
                  </div>
                  <div v-if="!requestType && !modelHouseTaskStatus"
                       class="col-12 d-flex justify-content-center mt-3 mb-5">
                    <button-with-icon
                        :icon-name="null"
                        :text="$t('sample.add_new_sample_product_info').toUpperCase()"
                        class="font-weight-boldest"
                        size="lg"
                        @onClick="addNewSampleProductInfo"
                    ></button-with-icon>
                  </div>
                  <!--                  ACCESSORIES-->
                  <div
                      v-if="isAllowToAddAccessories && Number(sample.sample_type_id) !== 2 && !accessoryRequestId && !fabricRequestId"
                      class="w-100 d-flex"
                      style="align-items: baseline;">
                    <button
                        class="btn btn-lg text-primary"
                        type="button"
                        @click="isAllowToAddAccessories = true">
                      <span v-html="getIconByKey('icons.sample.add', {
                        class: 'w-35px h-35px d-inline-block object-fill',
                      })"
                      ></span>
                      {{ $t('editSample.add_accessory') }}
                    </button>
                    <info-tooltip
                        :helper-text="$t('editSample.to_add_accessory')"
                    ></info-tooltip>
                  </div>
                  <div
                      v-if="isAllowToAddAccessories  && Number(sample.sample_type_id) !== 2 && !accessoryRequestId && !fabricRequestId"
                      class="col-lg-12 col-md-12 col-sm-12">
                    <custom-multi-select
                        :disabled="modelHouseTaskStatus"
                        :model.sync="selectedAccessories"
                        :options="accessoryOptions"
                        :placeholder="$t('general.select')"
                        :required="false"
                        :show-invalid="false"
                        :title="null"
                        name="accessories"
                        object-key="translations.0.name">
                    </custom-multi-select>
                  </div>
                  <div v-if="isAllowToAddAccessories  && Number(sample.sample_type_id) !== 2 && !fabricRequestId"
                       class="d-flex row col-lg-12 col-md-12 col-sm-12 m-0"
                  >
                    <div v-for="(value, index) in selectedAccessories"
                         v-if="(accessoryRequestId && Number(sample.accessory_sample[index].id) === Number(accessoryItemId)) || !accessoryRequestId"
                         class="col-xl-3 col-lg-3 col-md-6 col-sm-12  m-0 bg-gray-200 py-5"
                    >
                      <div
                          v-if="(accessoryRequestId && Number(sample.accessory_sample[index].id) === Number(accessoryItemId)) || !accessoryRequestId"
                          class="col-lg-12 col-md-12 col-sm-12">
                        <h4>{{ getValueByDottedKey(accessoryOptions[value], 'translations.0.name') }}

                          <span class="pt-2 px-1 w-60px">
                            <i role="button" class="fa fa-file-pdf cursor-pointer mr-2" @click.prevent="openInNewTab(sample.accessory_sample[index].sample_pdf_tag_link)"></i>

                            <i class="fa fa-eye"
                               role="button"
                               @click.prevent="showWaybills(sample.accessory_sample[index].accessory_id, 4, getValueByDottedKey(accessoryOptions[value], 'translations.0.name') + ' (' +sample.accessory_sample[index].variant + ')')">
                            </i>
                          </span>
                        </h4>
                      </div>
                      <div
                          v-if="(accessoryRequestId && Number(sample.accessory_sample[index].id) === Number(accessoryItemId)) || !accessoryRequestId"
                          class="col-lg-12 col-md-12 col-sm-12">
                        <text-input
                            :disabled="modelHouseTaskStatus"
                            :helper-text="$t('editSample.please_enter_color')"
                            :is-inline="true"
                            :is-valid="accessoriesValidation ? true : null"
                            :model.sync="sample.accessory_sample[index].variant"
                            :placeholder="$t('editSample.please_enter_color')"
                            :title="$t('editSample.color')"
                            name="variant"
                        ></text-input>
                      </div>
                      <div
                          v-if="(accessoryRequestId && Number(sample.accessory_sample[index].id) === Number(accessoryItemId)) || !accessoryRequestId"
                          class="col-lg-12 col-md-12 col-sm-10">
                        <number-input
                            :disabled="modelHouseTaskStatus"
                            :helper-text="$t('editSample.please_enter_consumption')"
                            :is-inline="true"
                            :is-valid="accessoriesValidation ? true : null"
                            :model.sync="sample.accessory_sample[index].consumption"
                            :placeholder="$t('editSample.please_enter_consumption')"
                            :step="1"
                            :title="$t('editSample.consumption')"
                            name="consumption"
                        ></number-input>
                      </div>
                      <div
                          v-if="(accessoryRequestId && Number(sample.accessory_sample[index].id) === Number(accessoryItemId)) || !accessoryRequestId"
                          class="col-lg-12 col-md-12 col-sm-12">
                        <custom-multi-select
                            :disabled="modelHouseTaskStatus"
                            :helper-text="$t('editSample.please_select_unit')"
                            :is-valid="accessoriesValidation ? true : null"
                            :max="1"
                            :model.sync="sample.accessory_sample[index].unit_id"
                            :not-list="true"
                            :options="unitOptions"
                            :placeholder="$t('editSample.please_select_unit')"
                            :title="$t('editSample.unit')"
                            name="unit"
                            object-key="translations.0.name"
                        ></custom-multi-select>
                        <span v-if="sample.accessory_sample[index].id < 0 && index >= 0" class="cursor-pointer p-0 m-0"
                              @click="deleteAccessoryProduct(index)"
                              v-html="getIconByKey('icons.sample.delete_red',{
                        class: 'w-15px h-15px d-inline-block mr-3 p-0 m-0 '
                      })"></span>
                      </div>
                      <div
                          v-if="sample.is_completed && !requestType && !modelHouseTaskStatus && sample.accessory_sample.length > 0 && Number(sample.sample_status_id) !== 3 && isUserGranted('Sample', ['create', 'update'], false) && Number(sample.accessory_sample[index].id) > 0 "
                          class="col-12 d-flex justify-content-center my-3">
                        <button-with-icon
                            :text="+sample.accessory_sample[index].is_request === 1 ? $t('sample.request_accessory_again').toUpperCase() : $t('sample.request_accessory').toUpperCase()"
                            class="font-weight-boldest"
                            icon-name="icons.sample.accessory"
                            size="lg"
                            @onClick="onClickRequestAccessory(index)"
                        ></button-with-icon>
                      </div>
                    </div>
                  </div>

                  <div
                      v-if="sample.is_completed && !requestType && !modelHouseTaskStatus && sample.accessory_sample.length > 0 && Number(sample.sample_status_id) !== 3 && isUserGranted('Sample', ['create', 'update'], false) && Number(sample.sample_type_id) !== 2 "
                      class="col-12 d-flex justify-content-center my-3">
                    <button-with-icon
                        :text="$t('sample.request_all_accessories').toUpperCase()"
                        class="font-weight-boldest"
                        icon-name="icons.sample.accessory"
                        size="lg"
                        @onClick="onClickRequestAccessory"
                    ></button-with-icon>
                  </div>
                </div>

                <h5
                    v-if="Number(sample.sample_type_id) === 3"
                    class="cursor-pointer pb-2 mb-5 d-flex justify-content-start pt-5">
                  <span class="bottom-border-1px-navy">
                    <span v-html="getIconByKey('icons.sample.accessory', {})"
                    ></span>
                    {{ $t('editSample.accessory_description').toUpperCase() }}</span>
                  <span class="cursor-pointer ml-2" @click="addNewAccessory" v-html="getIconByKey('icons.waybill.waybill_entry',{
                        class: 'w-20px h-20px d-inline-block mr-3'
                      })"></span>
                </h5>

                <div
                    v-if="isAllowToAddAccessories  && Number(sample.sample_type_id) === 3 && !accessoryRequestId && !fabricRequestId"
                    class="col-lg-12 col-md-12 col-sm-12">
                  <custom-multi-select
                      :disabled="modelHouseTaskStatus"
                      :model.sync="selectedAccessories"
                      :options="accessoryOptions"
                      :placeholder="$t('general.select')"
                      :required="false"
                      :show-invalid="false"
                      :title="null"
                      name="accessories"
                      object-key="translations.0.name">
                  </custom-multi-select>
                </div>

                <div v-if="isAllowToAddAccessories && Number(sample.sample_type_id) === 3 && !fabricRequestId"
                     class="d-flex row col-lg-12 col-md-12 col-sm-12 m-0"
                >
                  <div v-for="(value, index) in selectedAccessories"
                       v-if="(accessoryRequestId && Number(sample.accessory_sample[index].id) === Number(accessoryItemId)) || !accessoryRequestId"
                       class="col-xl-3 col-lg-3 col-md-6 col-sm-12  m-0 bg-gray-200 py-5"
                  >
                    <div
                        v-if="(accessoryRequestId && Number(sample.accessory_sample[index].id) === Number(accessoryItemId)) || !accessoryRequestId"
                        class="col-lg-12 col-md-12 col-sm-12">
                      <h4>{{ getValueByDottedKey(accessoryOptions[value], 'translations.0.name') }}</h4>
                    </div>
                    <div
                        v-if="(accessoryRequestId && Number(sample.accessory_sample[index].id) === Number(accessoryItemId)) || !accessoryRequestId"
                        class="col-lg-12 col-md-12 col-sm-12">
                      <text-input
                          :disabled="modelHouseTaskStatus"
                          :helper-text="$t('editSample.please_enter_color')"
                          :is-inline="true"
                          :is-valid="accessoriesValidation ? true : null"
                          :model.sync="sample.accessory_sample[index].variant"
                          :placeholder="$t('editSample.please_enter_color')"
                          :title="$t('editSample.color')"
                          name="variant"
                      ></text-input>
                    </div>
                    <div
                        v-if="(accessoryRequestId && Number(sample.accessory_sample[index].id) === Number(accessoryItemId)) || !accessoryRequestId"
                        class="col-lg-12 col-md-12 col-sm-10">
                      <number-input
                          :disabled="modelHouseTaskStatus"
                          :helper-text="$t('editSample.please_enter_consumption')"
                          :is-inline="true"
                          :is-valid="accessoriesValidation ? true : null"
                          :model.sync="sample.accessory_sample[index].consumption"
                          :placeholder="$t('editSample.please_enter_consumption')"
                          :step="1"
                          :title="$t('editSample.consumption')"
                          name="consumption"
                      ></number-input>
                    </div>
                    <div
                        v-if="(accessoryRequestId && Number(sample.accessory_sample[index].id) === Number(accessoryItemId)) || !accessoryRequestId"
                        class="col-lg-12 col-md-12 col-sm-12">
                      <custom-multi-select
                          :disabled="modelHouseTaskStatus"
                          :helper-text="$t('editSample.please_select_unit')"
                          :is-valid="accessoriesValidation ? true : null"
                          :max="1"
                          :model.sync="sample.accessory_sample[index].unit_id"
                          :not-list="true"
                          :options="unitOptions"
                          :placeholder="$t('editSample.please_select_unit')"
                          :title="$t('editSample.unit')"
                          name="unit"
                          object-key="translations.0.name"
                      ></custom-multi-select>
                      <span v-if="index >= 0" class="cursor-pointer p-0 m-0"
                            @click="deleteAccessoryProduct(index)"
                            v-html="getIconByKey('icons.sample.delete_red',{
                        class: 'w-15px h-15px d-inline-block mr-3 p-0 m-0 '
                      })"></span>
                    </div>
                    <div
                        v-if="sample.is_completed && !requestType && !modelHouseTaskStatus && sample.accessory_sample.length > 0 && Number(sample.sample_status_id) !== 3 && Number(sample.accessory_sample[index].id) > 0 "
                        class="col-12 d-flex justify-content-center my-3">
                      <button-with-icon
                          v-if="accsesRequestAccessory"
                          :text="+sample.accessory_sample[index].is_request === 1 ? $t('sample.request_accessory_again').toUpperCase() : $t('sample.request_accessory').toUpperCase()"
                          class="font-weight-boldest"
                          icon-name="icons.sample.accessory"
                          size="lg"
                          @onClick="onClickRequestAccessory(index)"
                      ></button-with-icon>
                    </div>
                  </div>
                </div>

                <div
                    v-if="sample.is_completed && !requestType && !modelHouseTaskStatus && sample.accessory_sample.length > 0 && Number(sample.sample_status_id) === 3 && Number(sample.sample_type_id) !== 2 "
                    class="col-12 d-flex justify-content-center my-3">
                  <button-with-icon
                      :text="$t('sample.request_all_accessories').toUpperCase()"
                      class="font-weight-boldest"
                      icon-name="icons.sample.accessory"
                      size="lg"
                      @onClick="onClickRequestAccessory"
                  ></button-with-icon>
                </div>

                <div class="row d-flex justify-content-start" v-if="false">
                  <div
                      v-for="(accessory_sample , index ) in  sample.accessory_sample"
                      v-if="Number(sample.sample_type_id) === 3"
                      class="col-4 row accessory-border"
                  >
                    <div class="d-flex col-lg-4 col-md-4 col-sm-12">
                      <text-input
                          :disabled="modelHouseTaskStatus || (accessory_sample.id && +sample.is_completed === 1) "
                          :helper-text="$t('editSample.please_enter_color')"
                          :is-inline="false"
                          :is-valid="accessoriesValidation ? true : null"
                          :model.sync="accessory_sample.variant"
                          :placeholder="$t('editSample.please_enter_color')"
                          :title="$t('editSample.color')"
                          name="variant"
                      ></text-input>
                    </div>
                    <div class="d-flex col-lg-4 col-md-4 col-sm-12">
                      <number-input
                          :disabled="modelHouseTaskStatus || (accessory_sample.id && +sample.is_completed === 1)"
                          :is-inline="false"
                          :is-valid="accessoriesValidation ? true : null"
                          :model.sync="accessory_sample.consumption"
                          :placeholder="$t('editSample.please_enter_consumption')"
                          :title="$t('editSample.consumption')"
                          name="consumption"
                      ></number-input>
                    </div>
                    <div class="d-flex col-lg-4 col-md-4 col-sm-12">
                      <custom-multi-select
                          :disabled="modelHouseTaskStatus || (accessory_sample.id && +sample.is_completed === 1)"
                          :helper-text="$t('editSample.please_select_unit')"
                          :is-inline="false"
                          :is-valid="accessoriesValidation ? true : null"
                          :max="1"
                          :model.sync="accessory_sample.unit_id"
                          :not-list="true"
                          :options="unitOptions"
                          :placeholder="$t('editSample.please_select_unit')"
                          :title="$t('editSample.unit')"
                          name="unit"
                          object-key="translations.0.name"
                      ></custom-multi-select>
                      <span v-if="false" class="cursor-pointer p-0 m-0" @click="deleteAccessory(index)"
                            v-html="getIconByKey('icons.sample.delete_red',{
                        class: 'w-15px h-15px d-inline-block mr-3 p-0 m-0 '
                      })"></span>
                    </div>
                  </div>
                </div>
                <template v-if="!requestType && !modelHouseTaskStatus && Number(sample.sample_status_id) !== 3">
                  <div class="d-flex justify-content-center mt-5 mb-3">
                <span class="px-4 py-2 gray-border-2px rounded-lg cursor-pointer d-inline-block"
                      @click="openAddNoteModal">
                  <span v-html="getIconByKey('icons.sample.add-note-2', {})"
                  ></span>
                   {{ $t('editSample.add_note') }}
                </span>
                  </div>
                  <div v-if="sample.note != null && sample.note.length !== 0"
                       class="d-flex justify-content-center my-5">
                    <p class="w-75 py-3 px-5 border-2 border-gray-300 text-center"
                       style="border: 1px solid rgba(50, 50, 50, 0.4); border-radius: 1rem;">
                      <i class="fas fa-pen"></i> <span> {{ sample.note }}</span>
                    </p>
                  </div>

                  <template v-if="Number(sample.is_completed)">
                    <div v-if="!isInCopyState" class="d-flex justify-content-center">
                      <button-with-icon
                          :text="$t('editSample.download_as_pdf').toUpperCase()"
                          class="text-primary mr-3"
                          icon-name="icons.sample.pdf"
                          size="md"
                          @onClick="openInNewTab(sample.sample_pdf_link)"
                      ></button-with-icon>
                    </div>
                    <div class="d-flex justify-content-center mt-4">
                      <button-with-icon
                          v-if="accesRepeatSample"
                          :disabled="is_submitting"
                          :icon-name="null"
                          :text="$t('editSample.repeat_the_sample').toUpperCase()"
                          class="text-primary"
                          size="md"
                          @onClick="repeatSample"
                      ></button-with-icon>
                      <template v-if="isInCopyState">
                        <button
                            class="btn btn-outline-danger btn-sm p-2 mr-3"
                            type="button" @click="$router.push({name: 'sample'})">
                          {{ $t('general.cancel') }}
                        </button>
                        <button
                            :disabled="is_submitting"
                            class="btn btn-outline-success btn-sm p-2"
                            @click="repeatSample">
                          {{ $t('general.submit') }}
                        </button>
                      </template>
                    </div>
                  </template>
                </template>
                <request-fabric-accessory
                    v-if="sample && sample.collection_number"
                    :meeting="meeting"
                    :request-type="requestType"
                    :sample="sample"
                ></request-fabric-accessory>
                <model-house-task-for-sample
                    :meeting="meeting"
                    :modelHouseId="$route.params.model_house_id"
                    :sample="sample"
                    :task-status="modelHouseTaskStatus"
                    :amountInformations="amountInformations"
                ></model-house-task-for-sample>
              </div>
            </b-collapse>
            <div v-if="!isInCopyState && !requestType && !modelHouseTaskStatus && id"
                 class="d-flex justify-content-center mt-5 btn-pill">
              <button-with-icon
                  :disabled="!accessoriesValidation && !is_submitting"
                  :icon-name="null"
                  :text="$t(Number(sample.is_completed) ? 'general.save' : 'createOriginalSample.save_and_create_instruction').toUpperCase()"
                  class="btn font-weight-bold btn-pill  ml-2 mr-2"
                  data-wizard-type="action-submit"
                  size="xl"
                  @onClick="submit"
              >
              </button-with-icon>

              <button-with-icon
                  :text="$t('editSample.cancel_this_sample').toUpperCase()"
                  class="text-primary"
                  icon-name="icons.sample.cancel"
                  size="md"
                  @onClick="cancelSample"
              ></button-with-icon>
            </div>
          </form>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapGetters} from "vuex"
import $ from 'jquery'

import MeetingImageHolderCard from "@/view/pages/samples/layout/meeting-detail/MeetingImageHolderCard";
import MeetingVideoHolderCard from "@/view/pages/samples/layout/meeting-detail/MeetingVideoHolderCard";
import MeetingAudioPlayerHolderCard from "@/view/pages/samples/layout/meeting-detail/MeetingAudioPlayerHolderCard";
import FileInput from "@/assets/components/inputs/FileInput";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import TextInput from "@/assets/components/inputs/TextInput";
import SelectInput from "@/assets/components/inputs/SelectInput";
import MultipleSelectInput from "@/assets/components/inputs/MultipleSelectInput";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import NumberInput from "@/assets/components/inputs/NumberInput";
import SampleImageSelectorModal from "@/view/pages/samples/layout/edit/SampleImageSelectorModal";
import VideoRecorder from "@/assets/components/recorder/VideoRecorder";
import AudioRecorder from "@/assets/components/recorder/AudioRecorder";
import PhotoRecorder from "@/assets/components/recorder/PhotoRecorder";
import ButtonWidthIcon from "@/assets/components/widget/ButtonWithIcon"
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon"
import AudioPlayer from "@/assets/components/mediaPlayer/AudioPlayer";
import MeetingTextHolderCard from "@/view/pages/samples/layout/meeting-detail/MeetingTextHolderCard";
import InfoTooltip from "@/assets/components/widget/InfoTooltip";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import InstructionsModal from "@/view/pages/shared/instructionsModal";
import SelectFilter from "@/assets/components/filters/SelectFilter";

import Vue from 'vue'
import VueRecord from '@codekraft-studio/vue-record'
import SelectSampleUserModal from "@/view/pages/samples/layout/meeting-detail/SelectSampleUserModal";
import {
  CREATE_ITEM,
  DELETE_ITEM_BY_ID,
  GET_ITEM_DETAIL_BY_ID,
  GET_ITEMS,
  LOADING,
  SET_LOADING,
  UPDATE_ITEM_BY_ID
} from "@/core/services/store/REST.module";
import RequestFabricAccessory from "@/view/pages/samples/layout/request-fabric-accessory/RequestFabricAccessory";
import moment from "moment";
import {LARAVEL_DATE_FORMAT, LARAVEL_DATE_TIME_FORMAT} from "@/core/config/constant";
import ModelHouseTaskForSample from "@/view/pages/model-house/ModelHouseTaskForSample";
import QRCode from "@/assets/components/widget/QRCode";

Vue.use(VueRecord)

export default {
  name: "SampleProductEdit",
  props: {},
  components: {
    QRCode,
    ModelHouseTaskForSample,
    RequestFabricAccessory,
    SelectSampleUserModal,
    ButtonWithIcon,
    VideoRecorder,
    AudioRecorder,
    PhotoRecorder,
    InfoTooltip,
    MeetingImageHolderCard,
    MeetingVideoHolderCard,
    MeetingAudioPlayerHolderCard,
    FileInput,
    TextareaInput,
    TextInput,
    SelectInput,
    MultipleSelectInput,
    DatePickerInput,
    NumberInput,
    SampleImageSelectorModal,
    AudioPlayer,
    MeetingTextHolderCard,
    CustomMultiSelect,
    ButtonWidthIcon,
    InstructionsModal,
    SelectFilter
  },
  data() {
    return {
      instructions: null,
      instructionTypes: null,
      fabricRequestId: null,
      currentHoveredItem: false,
      is_submitting: false,
      id: this.$route.params.id,
      modelHouseTaskStatus: this.$route.params.model_house_task_status,
      modelHouseId: this.$route.params.model_house_id,
      loaded: false,
      newProductContent: null,
      selectedFabricComposition: [],
      selectedAccessories: [],
      selectedVariants: [],
      sampleStatuses: [],
      defaultSizeJSON: {},
      meeting: null,
      amountInformations:{
        amount:0,
        delivered_amount:0
      },
      productContent: [],
      sample: {
        collection_number: null,
        name: null,
        code_link: null,
        order_date: moment(),
        users: [],
        default_content_id: null,
        sample_variations: [
          {
            color_number: null,
            color: null,
            body_sizes: {},
            amount: null
          }
        ],
        sample_product_informations: [
          {
            id: -1,
            selectedCompositions: [],
            fabric_type_id: null,
            color: null,
            color_number: null,
            is_request: 0,
            width: null,
            weight: null,
            fabric_composition_sample_product_informations: [],
            fabric_request: null
          }
        ],

        meeting_id: null,
        company_id: null,
        meeting_product_id: null,
        sample_type_id: null,
        sample_status_id: null,

        model_number: null,
        model_name: null,
        product_model_id: null,

        fabric_type_id: null,
        width: null,
        weight: null,

        amount: null,
        note: null,
        deadline: moment().format(LARAVEL_DATE_FORMAT),
        to_who: null,
        color: null,
        color_number: null,
        body_size_id: null,
        accessory_sample: [
          // {
          //   is_accessory_request_sent:0,
          // }
        ],
        sample_product_informations_length: 1,
        fabric_composition_sample: [],

        copied_id: 0,
        copied_ids: [],
        sample_pdf_link: null,
        sample_tag_pdf_link: null,
        completed_at: null,
      },

      productModelOptions: [],
      bodySizeOptions: [],
      fabricTypeOptions: [],
      fabricCompositionOptions: [],
      accessoryOptions: [],
      unitOptions: [],
      unitOptions2: [],
      expirationTypeOptions: [],

      wizardSecondTitle: 'editSample.meeting_notes',
      isAllowToAddAccessories: true,
      isInCopyState: false,
      tempNote: null,
      requestType: this.$route.params.requestType,
      accessoryRequestId: this.$route.params.accessoryRequestId,
      accessoryItemId: this.$route.params.itemId,
    }
  },
  computed: {
    ...mapGetters({
      'loading': LOADING,
    }),
    /*    formattedUserList() {
          if (this.usersList.length === 0) return [];
          let result = {};
          for (let i = 0; i < this.usersList.length; i++) {
            result[this.usersList[i].id] = this.usersList[i].name;
          }
          return result;
        },*/
    // VALIDATIONS
    modelNoValidation() {
      if (this.modelHouseTaskStatus) return false;
      if (Number(this.sample.sample_type_id) !== 1) return true;
      return !(this.sample.model_number == null || this.sample.model_number === '');
    },
    productForValidation() {
      if (this.modelHouseTaskStatus) return false;
      return !(this.sample.to_who == null || this.sample.to_who === '');

    },
    accesRepeatSample(){
      if(this.sample.sample_type_id==2 || this.sample.sample_type_id==3){
        return false;
      }
      return true;
    },
    accsesRequestAccessory(){
      if(this.sample.parent_id!=null && this.sample.sample_type_id==3){
        return false;
      }
      return true;
    },
    accessRequestFabric(){
      if(this.sample.parent_id!=null && this.sample.sample_type_id==2){
        return false;
      }
      return true;
    },
    deadlineValidation() {
      if (this.modelHouseTaskStatus) return false;
      return !(this.deadline === null);
    },
    modelNameValidation() {
      if (this.modelHouseTaskStatus) return false;
      if (Number(this.sample.sample_type_id) !== 1) return this.deadlineValidation;
      return !(this.sample.model_name === null || this.sample.model_name.length === 0);
    },
    modelDescriptionValidation() {
      if (this.modelHouseTaskStatus) return false;
      return Number(this.sample.product_model_id) !== 0;
    },
    sizeAndColorValidation() {
      // if (this.modelHouseTaskStatus) return false;
      return this.isNewColorAndSizeAddable;
    },
    fabricCompositionValidation() {
      if (Number(this.sample.sample_type_id) === 3) return true;
      if (this.sample.sample_product_informations.length === 0) return false;
      for (let i = 0; i < this.sample.sample_product_informations.length; i++) {
        const current = this.sample.sample_product_informations[i];
        if (Number(current.width) === 0) return false;
        if (Number(current.fabric_type_id) === 0) return false;
        if (current.color === null || current.color === '') return false;
        if (current.selectedCompositions.length === 0) return false;
        if (current.color_number === null || current.color_number === '') return false;
        let total = 0;
        for (let j = 0; j < current.fabric_composition_sample_product_informations.length; j++) {
          const tempCurrent = current.fabric_composition_sample_product_informations[j];
          total += Number(tempCurrent.percent);
        }
        if (total !== 100) return false;
      }
      return true;
    },
    // checkOwnerUsersForSample(){
    //   let authUser=this.authUser.user_id;
    //   let ownerUser=this.sample.user.id;
    //   let sampleSupervisors=this.sample.users.filter(user=>user.id==authUser);
    //   if(authUser===ownerUser||sampleSupervisors.length>0){
    //     return true;
    //   }
    //   return false;
    // },
    sizeAndColorTotalByIndex() {
      let size_and_colors = this.sample.sample_variations;
      return function (index) {
        let current = size_and_colors[index], total = 0;
        for (let key in current.body_sizes) {
          total += Number(current.body_sizes[key].amount);
        }
        return total;
      }
    },
    accessoriesValidation() {
      if (this.modelHouseTaskStatus) return false;
      if (!this.fabricCompositionValidation) return false;
      if (this.sample.accessory_sample.length === 0 && Number(this.sample.sample_type_id) === 3) return false;
      for (let i = 0; i < this.sample.accessory_sample.length; i++) {
        if (this.sample.accessory_sample[i].variant == null || this.sample.accessory_sample[i].variant === '') {
          return false;
        }
        if (Number(this.sample.accessory_sample[i].consumption) === 0) {
          return false;
        }
        if (Number(this.sample.accessory_sample[i].unit_id) === 0) {
          return false;
        }
      }
      return true;
    },
    isNewColorAndSizeAddable() {
      for (let i = 0; i < this.sample.sample_variations.length; i++) {
        let size_and_colors = this.sample.sample_variations;
        if (size_and_colors[i].color_number === null || size_and_colors[i].color_number === 0) return false;
        if (size_and_colors[i].color === null || size_and_colors[i].color === '') return false;
        if (size_and_colors[i].body_sizes === {}) return false;

      }
      return true;
    },
  },
  mounted() {
    let self = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("general.sample"), route: "sample"},
      {title: this.$t("general.edit_sample")}
    ]);

    if(!(this.isUserGranted('Sample', ['update', 'view'], false) || this.isUserGranted('ModelHouse', ['view'], false))) {
      setTimeout(() => {
        self.sweetAlertError(this.$t('general.you_have_no_authorized_to_do_this_action'));
      }, 500);
      return false;
    }

    let promises = [];
    let sampleId = self.$route.params.id;
    promises.push(this.$store.dispatch(GET_ITEMS, {
      url: 'api/body-sizes',
      showLoading: false,
      acceptPromise: true,
      filters: {
        sample_id: sampleId
      }
    }));
    promises.push(this.$store.dispatch(GET_ITEMS, {
      url: 'api/units',
      showLoading: false,
      acceptPromise: true,
    }));
    promises.push(this.$store.dispatch(GET_ITEMS, {
      url: 'api/fabric-types',
      showLoading: false,
      acceptPromise: true,
    }));
    promises.push(this.$store.dispatch(GET_ITEMS, {
      url: 'api/fabric-compositions',
      showLoading: false,
      acceptPromise: true,
    }));
    promises.push(this.$store.dispatch(GET_ITEMS, {
      url: 'api/accessories',
      filters: {
        all: true
      },
      showLoading: false,
      acceptPromise: true,
    }));

    promises.push(
      this.$store.dispatch(GET_ITEMS, {
      url: 'api/statuses',
      filters: {
        type_id: 1
      },
      acceptPromise: true,
      showLoading: false,
    }));
    this.fabricRequestId = this.$route.params.fabricRequestId;

    this.requestType = this.$route.params.requestType;

    Promise.all(promises).then((results) => {
      if (results[1].status) {
        // units
        self.unitOptions = self.convertArrayToObjectByKey(results[1].data, 'id');
        self.unitOptions2 = results[1].data
      }
      if (results[2].status) {
        // fabric type
        self.fabricTypeOptions = self.convertArrayToObjectByKey(results[2].data, 'id');
      }
      if (results[3].status) {
        // fabric compositions
        self.fabricCompositionOptions = self.convertArrayToObjectByKey(results[3].data, 'id');
      }
      if (results[4].status) {
        // accessories
        self.accessoryOptions = self.convertArrayToObjectByKey(results[4].data, 'id');
      }
      if (results[5].status) {
        // statuses
        self.sampleStatuses = self.convertArrayToObjectByKey(results[5].data, 'id', 'translations.0.name')
      }
      if (results[0].status) {
        self.bodySizeOptions = self.convertArrayToObjectByKey(results[0].data, 'id')

        let json = {};
        for (let key in self.bodySizeOptions) {
          json[key] = {
            body_size_id: key,
            amount: null,
            name: self.getValueByDottedKey(self.bodySizeOptions[key], 'translations.0.name')
          }
        }
        self.defaultSizeJSON = json;

        if (sampleId) {
          this.setUpSampleEdit();
        } else  {
          for (let i = 0; i < self.sample.sample_variations.length; i++) {
            self.$set(self.sample.sample_variations[i], 'body_sizes', self.defaultSizeJSON)
          }
        }
      } else {
        if (sampleId) {
          this.setUpSampleEdit();
        }
      }
    });

    if (sampleId) {
      this.setUpSampleEdit();
    }

    this.getInstructionTypes();
    /*    if (this.requestType) {
          this.$store.dispatch(GET_ITEMS, {
            url: 'api/users',
            acceptPromise: true,
            showLoading: false,
          }).then((result) => {
            if (result.status) {
              self.usersList = result.data;
            }
          })
        }*/
  },
  methods: {
    markAsCompleted(){
      let modelHouseId= this.$route.params.model_house_id;
      let self = this;

      this.sweetAlertConfirm(this.$t("general.are_you_sure")).then(result => {
        if(result) {
          self.$store.commit(SET_LOADING, true);

          self.$store.dispatch(GET_ITEMS, {
            url: `api/model-house/mark-as-completed-model-house/`+modelHouseId,
          }).then((result) => {
            self.$store.commit(SET_LOADING, false);
            if (result.status) {
            } else {
              console.log(result);
            }
          })
        }
      })
    },
    showWaybills(itemId, type = 1, name) {
      let self = this;
      if (type === 1) {
        this.$router.push({name: 'waybill.detail.index', params: {id: 'S-' + self.id, type: type, detailId: itemId, name: name}});
      }

      if (type === 4) {
        this.$router.push({name: 'waybill.detail.index', params: {id: 'S-' + self.id, type: type, detailId: itemId, name: name}});
      }
    },
    onCloseModal() {
      this.instructions = null;
      this.$refs['instructions-modal'].hide()
    },
    showInstructions() {
      let self = this;
      let filters = {
        instruction_type: 'S-' + self.id
      }
      self.$store.commit(SET_LOADING, true);

      self.$store.dispatch(GET_ITEMS, {
        url: `api/instructions`,
        filters: filters
      }).then((result) => {
        self.$store.commit(SET_LOADING, false);
        if (result.status) {
          self.instructions = result.data;
          this.$refs['instructions-modal'].show()
        } else {
          self.instructions = null;
        }
      })
    },
    showWaybillInvoiceEntrance() {
      let self = this;
      let filters = {
        instruction_type: 'S-' + self.id
      }
      self.$store.commit(SET_LOADING, true);

      self.$store.dispatch(GET_ITEMS, {
        url: `api/instructions`,
        filters: filters
      }).then((result) => {
        self.$store.commit(SET_LOADING, false);
        if (result.status) {
          self.instructions = result.data;
          self.$modal.show('instructions-modal');
        } else {
          self.instructions = null;
        }
      })
    },
    getInstructionTypes() {
      let self = this;
      this.$store.dispatch(GET_ITEMS, {
        url: 'api/instruction-type',
        acceptPromise: true,
      }).then((result) => {
        if (result.status) {
          self.instructionTypes = result.data;
        }
      })
    },
    mouseMove(e) {
      if (e === null) return;
      if (e.is_request !== 0) {
        this.currentHoveredItem = true;
        document.getElementById('message-pop-up').style.top = (e.pageY + 5) + 'px';
        document.getElementById('message-pop-up').style.left = (e.pageX - (20 / 2)) + 'px';
      }
    },
    mouseLeave(e) {
      e = null;
      this.currentHoveredItem = false;
    },
    deleteAccessoryProduct(index) {
      this.sample.accessory_sample.splice(index, 1)
      this.selectedAccessories.splice(index, 1)
    },
    deleteCompositions(index, key) {
      this.sample.sample_product_informations[index].fabric_composition_sample_product_informations.splice(key, 1)
      this.sample.sample_product_informations[index].selectedCompositions.splice(key, 1)
    },
    addNewSizeAndColor() {
      this.sample.sample_variations.push({
        color_number: null,
        color_name: null,
        body_sizes: this.cloneForce(this.defaultSizeJSON),
        amount: null,
      })
    },
    addNewAccessory() {
      this.sample.accessory_sample.push({
        variant: null,
        consumption: null,
        unit_id: null,
      })
    },
    deleteAccessory(index) {
      this.sample.accessory_sample.splice(index, 1)
    },
    deleteNewSizeAndColor(index) {
      this.sample.sample_variations.splice(index, 1);
    },
    addNewSampleProductInfo() {
      this.sample.sample_product_informations_length += 1;
      this.sample.sample_product_informations.push({
        selectedCompositions: [],
        fabric_type_id: null,
        color: null,
        color_number: null,
        is_request: 0,
        width: null,
        weight: null,
        fabric_composition_sample_product_informations: []
      })
    },
    onFabricCompositionsChange(payload) {
      let index = payload.name, oldValue = payload.oldValue, newValue = payload.newValue;
      let currentFabricComposition = this.sample.sample_product_informations[index];

      for (let i = 0; i < oldValue.length; i++) {
        if (!newValue.includes(oldValue[i])) {
          for (let j = 0; j < currentFabricComposition.fabric_composition_sample_product_informations.length; j++) {
            if (Number(currentFabricComposition.fabric_composition_sample_product_informations[j].fabric_composition_id) === Number(this.fabricCompositionOptions[oldValue[i]].id)) {
              currentFabricComposition.fabric_composition_sample_product_informations.splice(j, 1);
              break;
            }
          }
        }
      }
      let n = newValue.length - currentFabricComposition.fabric_composition_sample_product_informations.length;
      let remaining = 0;
      for (let i = 0; i < currentFabricComposition.fabric_composition_sample_product_informations.length; i++) {
        remaining += Number(currentFabricComposition.fabric_composition_sample_product_informations[i].percent);
      }
      let newPercent = 0;
      if ((100 - remaining) !== 0 || n > 0) {
        newPercent = ((100 - remaining) / n).toFixed(0);
      }

      for (let i = 0; i < newValue.length; i++) {
        if (!oldValue.includes(newValue[i])) {
          this.sample.sample_product_informations[index].fabric_composition_sample_product_informations.push({
            fabric_composition_id: this.fabricCompositionOptions[newValue[i]].id,
            percent: newPercent,
          });
        }
      }
    },
    productContentByType(type) {
      let results = [];
      for (let i = 0; i < this.productContent.length; i++) {
        let currentContent = this.productContent[i];
        if (Number(currentContent.content_type_id) === Number(type)) {
          results.push(currentContent);
        }
      }
      return results;
    },
    setErrorMessages() {
      let errorMessages = [];
      if (Number(this.sample.default_content_id) === 0 && this.sample.file_link === null) {
        errorMessages.push('editSample.please_select_image_first')
      }
      if (!this.modelNoValidation) {
        errorMessages.push(this.$t('editSample.please_enter_model_number'))
      }
      if (!this.modelNameValidation) {
        errorMessages.push(this.$t('editSample.please_enter_model_name'))
      }
      if (+this.sample.sample_type_id === 1 && !this.modelDescriptionValidation) {
        errorMessages.push(this.$t('editSample.please_select_product_description'))
      }
      if (+this.sample.sample_type_id === 1 && !this.sizeAndColorValidation) {
        errorMessages.push(this.$t('editSample.please_fill_all_the_required_field_from_color_and_size_description'))
      }
      if (!this.fabricCompositionValidation) {
        errorMessages.push(this.$t('editSample.please_fill_all_the_required_field_from_product_description'))
      }
      if (!this.accessoriesValidation) {
        errorMessages.push(this.$t('editSample.please_fill_all_the_required_field_from_accessories_description'))
      }
      if (errorMessages.length > 0) {
        this.sweetAlertError(errorMessages);
      }
      return errorMessages.length > 0;
    },
    submit() {
      if(!this.isUserGranted('Sample', ['create', 'update'])) {
        return false;
      }
      this.is_submitting = true;
      if (this.setErrorMessages()) {
        return false;
      }
      let self = this;
      let form = this.cloneForce(this.sample);

      if (form.deadline) {
        form.deadline = moment(form.deadline).format(LARAVEL_DATE_FORMAT)
      }

      if (form.order_date) {
        form.order_date = moment(form.order_date).format(LARAVEL_DATE_FORMAT)
      }
      for (let i = 0; i < form.sample_variations.length; i++) {
        if (Number(this.sample.sample_type_id) === 1) {
          form.sample_variations[i].amount = this.sizeAndColorTotalByIndex(i);
          form.amount += this.sizeAndColorTotalByIndex(i);
          let tempBodySize = form.sample_variations[i].body_sizes;
          for (let key in tempBodySize) {
            if (Number(tempBodySize[key].amount) === 0) delete tempBodySize[key];
          }
          let temp = JSON.stringify(tempBodySize);
          form.sample_variations[i] = {...form.sample_variations[i], body_sizes: temp};
        } else {
          let tempBodySize = form.sample_variations[i].body_sizes;
          for (let key in tempBodySize) {
            if (Number(tempBodySize[key].amount) === 0) delete tempBodySize[key];
          }
          let temp = JSON.stringify(tempBodySize);
          form.sample_variations[i] = {...form.sample_variations[i], body_sizes: temp};
        }
      }
      let sample_variations = [...form.sample_variations];
      for (let i = 0; i < sample_variations.length; i++) {
        if (Number(sample_variations[i].body_sizes) === []) {
          sample_variations[i].body_sizes = [];
        }
      }
      if (Number(form.sample_type_id) === 3) {
        let accessory_sample = [...form.accessory_sample];
        for (let i = 0; i < accessory_sample.length; i++) {
          if (Number(accessory_sample[i].accessory_id) === 0 || isNaN(accessory_sample[i].accessory_id) || accessory_sample[i].accessory_id === '' || Number(accessory_sample[i].accessory_id) === -1) {
            accessory_sample[i].accessory_id = this.accessoryOptions[Object.keys(this.accessoryOptions)[0]].id;
          }
        }
        form.sample_variations = [];
      } else {
        form.sample_variations = [...sample_variations];
      }

      this.$store.dispatch(UPDATE_ITEM_BY_ID, {
        url: 'api/samples/' + this.id,
        acceptPromise: true,
        contents: form,
      }).then((result) => {
        self.sweetAlertClose();
        if (result.status) {
          self.sweetAlertSuccess(self.$t('general.successfully_updated')).then((_) => {
            self.$router.push({name: 'sample'});
          })
        } else {
          let response = result.data.response;
          if (response.hasOwnProperty('data') && response.data.hasOwnProperty('message')) {
            self.sweetAlertError(response.data.message);
          } else {
            self.sweetAlertError(response.data);
          }
        }
      });
      return false;
    },
    onNewVideoSelected(payload) {
      let video = payload.file;
      let id = payload.id;
      let blobURL = URL.createObjectURL(video);
      $('#' + id).attr('src', blobURL);
    },
    onNewImageSelected(payload) {
      let image = payload.file;
      let id = payload.id;
      let reader = new FileReader();
      reader.onload = function (e) {
        $('#' + id).attr('src', e.target.result);
      }
      reader.readAsDataURL(image); // convert to base64 string
    },
    openAddNoteModal() {
      this.tempNote = this.sample.note;
      this.openModalByName('sample-note-modal');
    },
    openCreateNewImageContentFormModal() {
      if (this.modelHouseTaskStatus) return false;
      this.newProductContent = null
      this.$modal.show('create-new-image-content-form-modal')
    },
    openCreateNewVideoContentFormModal() {
      if (this.modelHouseTaskStatus) return false;
      this.newProductContent = null
      this.$modal.show('create-new-video-content-form-modal')
    },
    openCreateNewAudioContentFormModal() {
      if (this.modelHouseTaskStatus) return false;
      this.newProductContent = null
      this.$modal.show('create-new-audio-content-form-modal')
    },
    openCreateNewTextContentFormModal() {
      if (this.modelHouseTaskStatus) return false;
      this.newProductContent = null
      this.$modal.show('create-new-text-content-form-modal')
    },
    addMoreColorAndSize() {
      this.sample.sample_variations.push({
        id: -1,
        body_sizes: {},
        color: null,
        color_number: null,
        amount: 0,
      });
    },
    isColorAndSizeDeletable(index) {
      return this.sample.sample_variations.length > 1;
    },
    deleteColorAndSizeByIndex(index) {
      this.sample.sample_variations.splice(index, 1);
    },
    isSampleProductInformationDeletable(index) {
      return this.sample.sample_product_informations.length > 1;
    },

    deleteSampleProductInformationIndex(index) {
      this.sample.sample_product_informations.splice(index, 1);
      this.sample.sample_product_informations_length -= 1;
    },
    openSelectImageForSampleModal() {
      this.$modal.show('select-image-for-sample')
    },
    showWarningForInvalidFormClick(message = 'editSample.before_you_finished_previous_input_you_cant_fill_this_input') {
      if (Number(this.sample.default_content_id) === 0 && this.sampleContent === null) {
        message = 'editSample.please_select_image_first'
      }
      this.$swal.fire({
        icon: 'info',
        title: this.$t('editSample.info'),
        text: this.$t(message),
      })
    },
    uploadContent(contentTypeId, modelName) {
      if (this.is_submitting) return;
      this.is_submitting = true;
      let self = this;
      if (this.newProductContent == null) {
        this.setError(this.$t('editSample.please_fill_required_field_before_submitting'))
        return;
      }
      this.$modal.hide(modelName);
      let url = `api/samples/${this.id}/contents`;
      let form = new FormData();
      form.append('content', this.newProductContent);
      form.append('content_type_id', contentTypeId);

      this.$store.dispatch(CREATE_ITEM, {
        url: url,
        contents: form,
        acceptPromise: true,
      }).then((result) => {
        if (result.status) {
          self.productContent.push(result.data);
          self.sweetAlertSuccess(self.$t('general.successfully_uploaded'));
          self.is_submitting = false;
        }
      });
    },
    deleteContentById(id) {
      let index = null, self = this;
      for (let i = 0; i < this.productContent.length; i++) {
        if (Number(id) === Number(this.productContent[i].id)) {
          index = i;
          break;
        }
      }
      this.$store.dispatch(DELETE_ITEM_BY_ID, {
        url: `api/samples/${this.id}/contents/${id}`,
        acceptPromise: true,
      }).then((result) => {
        if (result.status) {
          self.productContent.splice(index, 1);
          self.sweetAlertSuccess(self.$t('general.successfully_deleted'))
        }
      })
    },
    onSampleImageSubmitted(payload) {
      this.sample.default_content_id = payload.id;
    },
    cancelSample() {
      let self = this;
      this.sweetAlertConfirm(this.$t('general.are_you_sure')).then((reply) => {
        if (reply) {
          self.$store.dispatch(UPDATE_ITEM_BY_ID, {
            url: `api/samples/${self.$route.params.id}`,
            acceptPromise: true,
            contents: {
              sample_status_id: 3,
            }
          }).then((result) => {
            if (result.status) {
              self.sweetAlertSuccess(self.$t('general.successfully_deleted')).then(() => {
                self.$router.go(-1);
              })
            }
          });
        }
      })
    },
    repeatSample() {
      if (this.is_submitting) return false;
      this.is_submitting = true;
      let self = this;
      let form = {...this.sample, ...{sample_id: this.id}}
      this.$store.dispatch(CREATE_ITEM, {
        url: 'api/samples/copy',
        contents: form,
        acceptPromise: true
      }).then((result) => {
        if (result.status) {
          self.sweetAlertSuccess(self.$t('general.successfully_created')).then((_) => {
            self.is_submitting = false;
            self.$router.replace({name: 'sample.product.edit', params: {id: result.data.id}})
          })
        }
      })
    },
    addZerosToId(id) {
      if (id < 9) {
        return '0000' + id;
      } else if (id < 99) {
        return '000' + id;
      } else if (id < 999) {
        return '00' + id;
      } else if (id < 9999) {
        return '0' + id;
      } else {
        return id;
      }
    },
    closeModalAndSave() {
      this.sample.note = this.tempNote;
      this.tempNote = null;
      this.closeModalByName('sample-note-modal');
    },
    closeModalAndCancel() {
      this.tempNote = null;
      this.closeModalByName('sample-note-modal');
    },
    onClickRequestFabric(index) {
      if(!(this.isUserGranted('Sample', ['create', 'update'], false) || this.isUserGranted('FabricRequest', ['create'], false))) {
        setTimeout(() => {
          this.sweetAlertError(this.$t('general.you_have_no_authorized_to_do_this_action'));
        }, 500);
      }

      if (this.sample.sample_product_informations[index].is_request === 1) {
        this.sweetAlertError('sample.the_fabric_has_request');
        return;
      }

      if (this.is_submitting) return;
      this.is_submitting = true;
      this.sample.sample_product_informations[index].is_request = 1,
          this.sweetAlertConfirm(this.$t('sample.request_fabric_confirm_message'), null, null, {
            imageUrl: this.getIconByKey('icons.sample_instruction.request_fabric_pop_up'),
            confirmButtonText: this.$t('general.yes').toUpperCase(),
            cancelButtonText: this.$t('general.give_up').toUpperCase(),
            confirmButtonClass: 'btn btn-outline-primary',
            cancelButtonClass: 'btn btn-outline-primary',
          }).then((result) => {
            if (result) {
              // TODO: send api request to create request fabric
              let self = this;
              this.$store.dispatch(CREATE_ITEM, {
                url: 'api/fabric-requests',
                contents: {
                  sample_id: self.id,
                  fabric_type_id: self.sample.sample_product_informations[index].fabric_type_id,
                  sample_product_information_id: self.sample.sample_product_informations[index].id,
                  fabric_supply_type_id: 1, // TODO:have to be null or 0
                  company_id: self.sample.company_id,
                  is_completed: 0,

                  // TODO:need to remove these fields
                  width: this.sample.sample_product_informations[index].width,
                  weight: this.sample.sample_product_informations[index].weight,
                  note: this.sample.note,
                },
                acceptPromise: true,
              }).then((result) => {
                if (result.status) {
                  self.sweetAlertSuccess(self.$t('sample.request_fabric_success_message'));
                  self.is_submitting = false;

                } else {
                  self.sweetAlertError(self.$t('general.internal_error'));
                  self.is_submitting = false;

                }
              })
            } else {
              self.is_submitting = false;

            }
          })
    },
    onClickRequestAccessory(index = null) {
      if(!this.isUserGranted('Sample', ['create', 'update'], false) || !this.isUserGranted('AccessoryRequest', ['create'], false)) {
        setTimeout(() => {
          this.sweetAlertError(this.$t('general.you_have_no_authorized_to_do_this_action'));
        }, 500);
      }

      if (this.is_submitting) return;
      this.is_submitting = true;
      let self = this;
      this.sweetAlertConfirm(this.$t('sample.request_accessory_confirm_message'), null, null, {
        imageUrl: this.getIconByKey('icons.sample_instruction.accessory_pop_up'),
        confirmButtonText: this.$t('general.yes').toUpperCase(),
        cancelButtonText: this.$t('general.give_up').toUpperCase(),
        confirmButtonClass: 'btn btn-outline-primary',
        cancelButtonClass: 'btn btn-outline-primary',
      }).then((result) => {
        if (result) {
          if (index === null) {
            let promises = [];
            for (let i = 0; i < self.sample.accessory_sample.length; i++) {
              let current = self.sample.accessory_sample[i];
              if (current.is_request !== 1) {
                promises.push(this.$store.dispatch(CREATE_ITEM, {
                  url: 'api/accessory-requests',
                  acceptPromise: true,
                  contents: {
                    accessory_sample_id: current.id,
                    sample_id: self.id,
                    accessory_id: current.accessory_id,
                    is_request: 1,
                    is_completed: 0,
                    variant: current.variant,
                    consumption: current.consumption,
                    unit: current.unit_id,
                    note: self.sample.note,
                  }
                }));
              }
              this.sample.accessory_sample[i].is_request = 1;
            }
            Promise.all(promises).then((results) => {
              let allGood = true;
              for (let i = 0; i < results.length; i++) {
                if (!results[i].status) {
                  allGood = false;
                }
              }
              if (allGood) {
                self.sweetAlertSuccess(this.$t('sample.request_accessory_success_message'))
              } else {
                self.sweetAlertError(this.$t('general.internal_error'));
              }
            });
          } else {
            if (this.sample.accessory_sample[index].is_request === 1) {
              this.sweetAlertError('sample.the_accessory_has_request');
              return;
            }
            this.$store.dispatch(CREATE_ITEM, {
              url: 'api/accessory-requests',
              acceptPromise: true,
              contents: {
                accessory_sample_id: self.sample.accessory_sample[index].id,
                sample_id: self.id,
                accessory_id: self.sample.accessory_sample[index].accessory_id,
                is_completed: 0,
                variant: self.sample.accessory_sample[index].variant,
                consumption: self.sample.accessory_sample[index].consumption,
                unit: self.sample.accessory_sample[index].unit_id,
                note: self.sample.note,
              }
            }).then(_ => {
              if (_.status) {
                self.sweetAlertSuccess(this.$t('sample.request_accessory_success_message'))
              } else {
                self.sweetAlertError(this.$t('general.internal_error'));
              }
              self.is_submitting = false;
              this.sample.accessory_sample[index].is_request = 1;
            });
          }
        } else {
          self.is_submitting = false;
        }
      })
    },
    setUpSampleEdit() {
      this.sweetAlertLoading()
      this.loaded = false;
      let self = this;
      this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {
        url: 'api/samples/' + this.id,
        acceptPromise: true,
      }).then((result) => {
        if (result.status) {
          let temp = result.data;
          if (Number(temp.status_id) === 3 && !this.isUserGranted('Sample', ['create', 'update'], false)) {
            self.sweetAlertError(self.$t('general.no_record_found')).then((_) => {
              self.$router.go(-1);
            })
          }
          self.sample.collection_number = temp.collection_number;
          self.sample.name = temp.name;
          self.sample.code_link = temp.code_link;
          self.sample.code = temp.code;
          self.sample.users = temp.users;
          self.sample.image = temp.image;
          self.sample.default_content_id = Number(temp.default_content_id);
          self.sample.is_completed = Number(temp.is_completed);
          self.sample.order_date = moment(temp.order_date, LARAVEL_DATE_FORMAT);
          self.sample.completed_at = temp.completed_at ? moment(temp.completed_at, LARAVEL_DATE_TIME_FORMAT) : null;
          self.sample.meeting_id = temp.meeting_id;
          self.sample.meeting_product_id = temp.meeting_product_id;
          self.sample.sample_type_id = temp.sample_type_id;
          self.sample.sample_status_id = temp.sample_status_id;
          self.sample.product_model_id = temp.product_model_id;
          self.sample.model_number = temp.model_number;
          self.sample.model_name = temp.model_name;
          self.sample.company_id = temp.company_id;
          self.sample.company = temp.company;
          self.sample.user = temp.user;
          self.sample.fabric_type_id = temp.fabric_type_id;
          self.sample.width = temp.width;
          self.sample.weight = temp.weight;
          self.sample.is_request = temp.is_request;
          self.sample.amount = temp.amount;
          self.sample.note = temp.note;
          self.sample.color = temp.color;
          self.sample.sample_pdf_link = temp.sample_pdf_link;
          self.sample.sample_tag_pdf_link = temp.sample_tag_pdf_link;
          self.sample.color_number = temp.color;
          self.sample.deadline = temp.deadline ? moment(temp.deadline, LARAVEL_DATE_FORMAT) : moment().format(LARAVEL_DATE_FORMAT);
          self.sample.to_who = temp.to_who;
          self.sample.body_size_id = temp.body_size_id;
          self.sample.sample_type = temp.sample_type;
          self.sample.copied_id = temp.copied_id;
          self.sample.copied_ids = temp.copied_ids;
          self.sample.parent_id = temp.parent_id;
          self.productContent = temp.contents;
          self.copied_id = temp.copied_id;
          self.copied_ids = temp.copied_ids;
          self.meeting = temp.meeting;
          self.amountInformations.delivered_amount = temp.delivered_amount;
          self.amountInformations.amount=temp.amount;

          this.$store.dispatch(GET_ITEMS, {
            url: 'api/product-models',
            filters: {
              company_id: temp.company_id
            },
            showLoading: false,
            acceptPromise: true,
          }).then(response => {
            if (response.status) {
              self.productModelOptions = self.convertArrayToObjectByKey(response.data, 'id');
            }
          });

          if (temp.sample_variations !== undefined && temp.sample_variations.length > 0) {
            self.sample.sample_variations = temp.sample_variations;
            for (let i = 0; i < temp.sample_variations.length; i++) {
              let tempData = JSON.parse(temp.sample_variations[i].body_sizes);
              self.sample.sample_variations[i].body_sizes = tempData
              self.$set(self.sample.sample_variations[i], 'body_sizes', tempData)
            }
          } else {
            for (let i = 0; i < self.sample.sample_variations.length; i++) {
              self.$set(self.sample.sample_variations[i], 'body_sizes', self.defaultSizeJSON)
            }
          }
          if (temp.accessory_sample !== undefined && temp.accessory_sample.length > 0) {
            self.sample.accessory_sample = temp.accessory_sample;
          }
          self.selectedAccessories = [];
          if (Number(temp.sample_type_id) === 3 && temp.accessory_sample.length === 0) {
            let accessory_id = self.accessoryOptions[Object.keys(self.accessoryOptions)[0]].id;
            self.sample.accessory_sample = [{
              id: -1,
              accessory_id: accessory_id,
              sample_id: 8,
              variant: null,
              consumption: null,
              is_request: 0,
              unit_id: null,
            }];

            self.selectedAccessories.push(accessory_id.toString())
          }
          if ('accessory_sample' in temp && temp.accessory_sample !== undefined) {
            for (let i = 0; i < temp.accessory_sample.length; i++) {
              self.selectedAccessories.push(temp.accessory_sample[i].accessory_id.toString())
            }
          }

          self.sample.sample_product_informations = [];
          if ('sample_product_informations' in temp && temp.sample_product_informations !== undefined) {
            // TODO: need to check again(not sure about this)
            self.sample.sample_product_informations = [...temp.sample_product_informations];
            for (let i = 0; i < temp.sample_product_informations.length; i++) {
              self.sample.sample_product_informations[i].selectedCompositions = [];
              for (let j = 0; j < temp.sample_product_informations[i].fabric_composition_sample_product_informations.length; j++) {
                self.sample.sample_product_informations[i]['selectedCompositions'].push(temp.sample_product_informations[i].fabric_composition_sample_product_informations[j].fabric_composition_id.toString())
              }
            }
          }
          if (Number(self.sample.sample_type_id) === 3) {
            self.sample.sample_product_informations = [];
          }
          if (self.sample.sample_product_informations.length === 0 && Number(self.sample.sample_type_id) !== 3) {
            self.sample.sample_product_informations.push({
              selectedCompositions: [],

              fabric_type_id: 1,
              color: null,
              color_number: null,
              width: null,
              weight: null,
              is_request: 0,
              fabric_composition_sample_product_informations: []
            });
          }
          self.loaded = true;
          self.sweetAlertClose();
        } else {
          self.sweetAlertError(self.$t('general.no_record_found')).then((_) => {
            self.$router.go(-1);
          })
        }
      })
    },
  },
  watch: {
    'sample.sample_variations'(newValue, oldValue) {
      if (this.sample.sample_type_id != 1) {
        this.sample.sample_variations.amount = newValue.amount;
      }
    },
    '$route'(to, from) {
      this.id = this.$route.params.id;
      this.setUpSampleEdit();
    },
    selectedAccessories(newValue, oldValue) {
      if (newValue === undefined || oldValue === undefined) return;
      for (let i = 0; i < oldValue.length; i++) {
        if (!newValue.includes(oldValue[i])) {
          for (let j = 0; j < this.sample.accessory_sample.length; j++) {
            if (Number(this.sample.accessory_sample[j].accessory_id) === Number(this.accessoryOptions[oldValue[i]].id)) {
              this.sample.accessory_sample.splice(j, 1);
              break;
            }
          }
        }
      }
      for (let i = 0; i < newValue.length; i++) {
        if (!oldValue.includes(newValue[i]) && !(newValue[i].toString() in this.convertArrayToObjectByKey(this.sample.accessory_sample, 'accessory_id'))) {
          this.sample.accessory_sample.push({
            id: -1,
            accessory_id: Number(this.accessoryOptions[newValue[i]].id),
            variant: null,
            consumption: null,
            unit_id: 0
          });
        }
      }
    },
    sample: {
      handler: function (newValue) {
        if (newValue == null) return;
        if (newValue.meeting_id !== undefined || Number(newValue.meeting_id) === 0) {
          this.wizardSecondTitle = 'editSample.collection_description'
        }
      },
      deep: true,
    },
  }
}
</script>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";

.flex-40 {
  flex: 40% !important;;
}

.flex-60 {
  flex: 60% !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.border-gray-300 {
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}

.add-product-content-btn {
  padding: 15px;
  border: 1px solid rgba(75, 85, 99, 0.6);
  border-radius: 50%;

  i {
    font-size: 20px;
    width: 20px;
    height: 20px;
    align-content: center;
  }
}

.add-product-content-btn:hover {
  border-color: var(--primary);
}

*:focus {
  outline: 0 !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.text-underline-gray {
  border-bottom: 2px solid rgba(150, 150, 150, 0.6);
}

.card {
  border-radius: 35px !important;
  background-color: rgba(255, 255, 255, 0.5);
  //box-shadow: 10px 10px rgba(100, 100, 100, 0.2), -10px 10px rgba(100, 100, 100, 0.2);
}

.accessory-border {
  border: 1px solid #212c40;
  padding: 5px 0 !important;
  border-radius: 10px;
  margin-right: 3px;
  margin-left: 3px;
  margin-top: 5px;
}
</style>
