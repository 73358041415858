<template>
  <div class="px-5">
    <template v-for="(currentDetail, index, i) in modelProxy">
      <div :class="{'mt-5': +i !== 0}" 
      :ref="`ready_${currentDetail.id}`"
      :id="`ready_${currentDetail.id}`"
      class="navy-blue-border-1px border-15px">
        <div class="d-flex navy-blue-border-bottom-1px">
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.supplier_company') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.processes') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.dispatch_location') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('sample.to_who') }}
          </div>
        </div>
        
        <div class="d-flex">
          <div class="flex-1 d-flex justify-content-center items-center py-2 text-center navy-blue-border-right-1px">
            <custom-multi-select :has-bottom-margin="false" :item-per-row="1" :max="1"
              :disabled="currentDetail.lockable_company &&  isUserGranted('Sample', ['updateInstructionSupplierCompany'],false,true)"
              :model.sync="currentDetail.supplier_company_id" :name="`order_fabric_yarn_supplier_company_${index}`"
              :not-list="true" :options="supplierCompanyOptions" :required="false" :title="null" size="sm">
            </custom-multi-select>
          </div>
          <div class="flex-1 d-flex justify-content-center items-center py-2 text-center navy-blue-border-right-1px">
            <custom-multi-select :has-bottom-margin="false" :item-per-row="1"
              :model.sync="currentDetail.fabric_process_ids" :name="`order_fabric_ready_made_yarn_type_${index}`"
              :options="fabricProcessOptions" :required="false" :showInvalid="false" :title="null" size="sm">
            </custom-multi-select>
          </div>
          <div class="flex-1 d-flex justify-content-center items-center py-2 text-center navy-blue-border-right-1px">
            <custom-multi-select :has-bottom-margin="false" :is-right="true" :item-per-row="1" :max="1"
              :model.sync="currentDetail.dispatch_location_id"
              :name="`order_fabric_ready_made_dispatch_location_${index}`" :options="dispatchLocationOptions"
              :required="false" :title="null" size="sm" :not-list="true"></custom-multi-select>
          </div>
          <div class="flex-1 d-flex justify-content-center items-center py-2 text-center navy-blue-border-right-1px">
            <input v-model="currentDetail.to_who" :class="{'is-invalid': !currentDetail.to_who}"
              :name="`order_fabric_ready_made_to_who_${index}`" :placeholder="$t('sample.to_who')" :required="false"
              :title="null" class="form-control form-control-sm" type="text">
          </div>
        </div>
        <div class="d-flex navy-blue-border-bottom-1px navy-blue-border-top-1px">
          
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.fabric_type') }}
          </div>
          <div class="flex-1 py-2 text-center">
            {{ $t('order.color_ok') }}
          </div>
        </div>
        <div class="d-flex">
          <div class="flex-1 d-flex justify-content-center items-center py-2 text-center navy-blue-border-right-1px">
            <input v-model="currentDetail.fabric_type" :class="{'is-invalid': !currentDetail.fabric_type}"
              :name="`order_fabric_ready_made_fabric_type_${index}`" :placeholder="$t('order.please_enter_fabric_type')" :required="true"
              :title="null" class="form-control form-control-sm" type="text">
          </div>
          <div class="flex-1 d-flex justify-content-center items-center py-2 text-center">
            <input v-model="currentDetail.color_ok" :class="{'is-invalid': !currentDetail.color_ok}"
              :name="`order_fabric_ready_made_color_ok_${index}`" :placeholder="$t('order.color_ok')" :required="true"
              :title="null" class="form-control form-control-sm" type="text">
          </div>
        </div>
        <date-detail-table-row :model="currentDetail" :type="1"></date-detail-table-row>

        <div class="d-flex navy-blue-border-top-1px">
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.amount') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.unit') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.currency_unit') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.exchange_rate') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.vade') }}
          </div>
          <div class="flex-1 py-2 text-center navy-blue-border-right-1px">
            {{ $t('order.unit_price') }}
          </div>
          <div class="flex-1 py-2 text-center">
            {{ $t('order.total_price') }}
          </div>
        </div>
        <div class="d-flex navy-blue-border-top-1px">
          <div
            class="flex-1 py-2 d-flex flex-column items-center justify-content-center text-center navy-blue-border-right-1px">
            <input v-model="currentDetail.amount" :class="{'is-invalid': !+currentDetail.amount}"
              :name="`order_fabric_ready_made_amount_${index}`" :placeholder="$t('order.please_enter_quantity')"
              :required="false" :title="null" class="form-control form-control-sm" type="number">
          </div>
          <div
            class="flex-1 py-2 d-flex flex-column items-center justify-content-center text-center navy-blue-border-right-1px">
            <custom-multi-select :has-bottom-margin="false" :item-per-row="2" :max="1"
              :model.sync="currentDetail.amount_unit_id" :name="`amount_unit_id_${index}`" :not-list="true"
              :options="amountUnitOptions" :required="false" :title="null" size="sm"></custom-multi-select>
          </div>
          <div
            class="flex-1 py-2 d-flex flex-column items-center justify-content-center text-center navy-blue-border-right-1px">
            <custom-multi-select :has-bottom-margin="false" :item-per-row="2" :max="1"
              :model.sync="currentDetail.currency_unit_id" :name="`currency_unit_id_${index}`" :not-list="true"
              :options="currencyOptions" :required="false" :title="null" size="sm"
              @save="onExpenseDetailCurrencyChange($event, index)"></custom-multi-select>
          </div>
          <div class="flex-1 py-2 d-flex flex-column justify-content-center text-center navy-blue-border-right-1px">
            {{ currentDetail.exchange_rate }}
          </div>
          <div
            class="flex-1 py-2 d-flex flex-column items-center justify-content-center text-center navy-blue-border-right-1px">
            <custom-multi-select :has-bottom-margin="false" :item-per-row="2" :max="1"
              :model.sync="currentDetail.expiry_id" :name="`expiry_${index}`" :not-list="true"
              :options="expirationTypeOptions" :required="false" :title="null" object-key="name" size="sm">
            </custom-multi-select>
          </div>
          <div class="flex-1 d-flex flex-column justify-content-center py-2 text-center navy-blue-border-right-1px">
            <div class="px-2">
              <input v-model="currentDetail.price" :class="{'is-invalid': !+currentDetail.price}"
                :name="`price_${index}`" :placeholder="$t('order.please_enter_unit_price')" :required="false"
                class="form-control form-control-sm" min="0" step="0.01" type="number" />
            </div>
          </div>
          <div class="flex-1 py-2 text-center">
            {{ totalAmount(index) }} {{ currencyOptions[currentDetail.currency_unit_id] }}
          </div>
        </div>

        <div class="d-flex navy-blue-border-top-1px">
          <div class="flex-1 px-2 py-3">
            <textarea-input :is-valid="!!currentDetail.description" :model.sync="currentDetail.description"
              :name="`order_fabric_ready_made_description_${index}`" :required="false" :title="$t('order.description')">
            </textarea-input>
          </div>
        </div>
      </div>
      <span v-if="index > 0 && !currentDetail.id" class="cursor-pointer" @click="deleteNewDetail(index)" v-html="getIconByKey('icons.sample.delete_red',{
        class: 'w-20px h-20px d-inline-block mr-3 '
      })">

      </span>

      <span v-if="isValidToSubmit && index == modelProxy.length - 1" class="cursor-pointer" @click="addNewDetail"
        v-html="getIconByKey('icons.waybill.waybill_entry', {
          class: 'w-50px h50px d-line-block object-fill',
        })"></span>

      <bottom-buttons :emails="[]" :is-editing.sync="currentDetail.is_editing"
        :is-valid-to-submit="isValidToSubmit(index)" :model="currentDetail" :order-id="orderId"
        :send-mail-url="sendMailUrl(index)" name="yarn-request" @addNewDetail="addNewDetail"
        @onCancelInstruction="onCancelInstruction(currentDetail.id, index)"
        @submit="submitFabricReadyMadeRequest (index)"></bottom-buttons>
    </template>
  </div>
</template>

<script>
import _ from "lodash";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import TestMethodRow from "@/view/pages/samples/layout/request-fabric-accessory/layout/TestMethodRow";
import CurrencyTableRow from "@/view/pages/samples/layout/request-fabric-accessory/layout/CurrencyTableRow";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import DateDetailTableRow from "@/view/pages/samples/layout/request-fabric-accessory/layout/DateDetailTableRow";
import BottomButtons from "@/view/pages/samples/layout/request-fabric-accessory/layout/BottomButtons";
import moment from "moment";
import { LARAVEL_DATE_TIME_FORMAT } from "@/core/config/constant";
import { CREATE_ITEM, DELETE_ITEM_BY_ID } from "@/core/services/store/REST.module";

export default {
  name: "OrderFabricReadyMadeTypeTable",
  components: {
    BottomButtons,
    DateDetailTableRow,
    TextareaInput,
    CurrencyTableRow,
    TestMethodRow,
    DatePickerInput,
    CustomMultiSelect
  },
  props: {
    model: {
      required: true,
    },
    defaultModel: {
      required: true,
    },
    orderId: {
      required: true,
      default: null
    },
    order: {
      required: true,
      default: null
    },
    orderFabricId: {
      required: true,
    },
    maxTolerance: {
      required: true,
      default: 0,
    },
    currentInstructionTypeId: {
      required: true,
    },
    fabricProcessOptions: {
      required: true,
    },
    currencyOptions: {
      required: true
    },
    amountUnitOptions: {
      required: true
    },
    currencyOptionsRaw: {
      required: true
    },
    supplierCompanyOptions: {
      required: true,
    },
    fabricYarnTypesOptions: {
      required: true,
    },
    dispatchLocationOptions: {
      required: false,
    },
    expirationTypeOptions: {
      required: true,
    },
    currencyRateOptions: {
      required: true,
    },
    didChangeProcessingType: {
      required: true,
      type: Boolean,
    },
    productProcesses: {
      required: true,
    },
    fabricProcessingType: {
      required: true,
    },
    netIncomingFabricAmount: {
      required: true,
    }
  },
  data() {
    return {
      is_submitted: false,
    }
  },
  methods: {
    onExpenseDetailCurrencyChange(payload, index) {
      let newValue = payload.newValue, oldValue = payload.oldValue;
      let rate = 1;
      this.modelProxy[index].exchange_rate = rate;

      if (!newValue) return;
      if (newValue[0] in this.currencyOptions) {
        rate = Number(this.currencyRateOptions[this.currencyOptions[newValue[0]]].rate);
      }
      this.modelProxy[index].exchange_rate = rate;
    },
    onCancelInstruction(itemId, index) {
      const self = this;
      this.$store.dispatch(DELETE_ITEM_BY_ID, {
        url: `api/fabric-request/${self.orderFabricId}/ready-made-request/${itemId}`,
      }).then(function (response) {
        if (response.status) {
          self.sweetAlertSuccess(self.$t('general.successfully_deleted'));
          self.modelProxy.splice(index, 1);
          if (!self.modelProxy.length) {
            let defaultModelClone = _.cloneDeep(self.defaultModel);
            defaultModelClone.is_editing = false;
            defaultModelClone.is_submitted = false;
            self.modelProxy = [defaultModelClone];
          }
        } else {
          self.sweetAlertError(response.data.message);
        }
      });
    },
    addNewDetail() {
      let copy = _.cloneDeepWith(this.defaultModel);
      copy.is_submitted = false;
      copy.is_editing = false;
      copy.id = null;

      this.sweetAlertLoading();
      this.$emit("getIncomingFabricAmount");
      setTimeout(() => {
        this.modelProxy.push(copy);
        this.sweetAlertClose();
      }, 1000);
    },
    deleteNewDetail(index) {
      this.modelProxy.splice(index, 1);
    },
    fixedData(index) {
      let items = [];
      let self = this;
      let item = this.modelProxy[index];

      item.fabric_processes = item.fabric_process_ids.map(process => {
        return {
          id: process,
        };
      });

      item.fabric_request_id = self.orderFabricId;
      item.raw_flow_date = moment(item.raw_flow_date).format(LARAVEL_DATE_TIME_FORMAT);
      item.raw_end_date = moment(item.raw_end_date).format(LARAVEL_DATE_TIME_FORMAT);

      items.push(item);

      return items;
    },
    submitFabricReadyMadeRequest(index) {
      if (this.is_submitting) return false;
      this.is_submitting = true;
      this.sweetAlertLoading();
      this.updateOrCreate(index);
    },
    updateOrCreate(index) {
      const self = this;
      self.$store.dispatch(CREATE_ITEM, {
        url: `api/fabric-request/${self.orderFabricId}/ready-made-request`,
        contents: {
          order_ready_made_request: self.fixedData(index),
        }
      }).then(response => {
        if (response.status) {
          let detail = self.modelProxy[index];
          let is_editing = !!detail.id;
          let responseData = response.data[0];
          detail.id = responseData.id
          detail.is_submitted = true;
          detail.is_editing = false;
          detail.pdf_link = responseData.pdf_link;
          self.modelProxy[index] = detail;
          self.sweetAlertSuccess(self.$t(is_editing ? 'general.successfully_updated' : 'general.successfully_created'));
        } else {
          let result = response.data.response;
          if (result.hasOwnProperty('data') && result.data.hasOwnProperty('message')) {
            self.sweetAlertError(result.data.message);
          } else {
            self.sweetAlertError(result.data);
          }
        }
        self.sweetAlertClose();
        self.is_submitting = false;
      });
    }
  },
  computed: {
    modelProxy: {
      get() {
        return this.model;
      },
      set(value) {
        this.$emit('update:model', value)
      }
    },
    defaultColor() {
      return this.order.sample_product_informations.find(color => color.sample_id === color.fabric_request.sample_id)
       
    },
    totalAmount() {
      return function (index) {
        if (!this.modelProxy[index].amount) {
          return 0;
        }
        this.modelProxy[index].total_amount = this.modelProxy[index].amount * this.modelProxy[index].price;
        return (+this.modelProxy[index].total_amount).toFixed(0) || 0;
      }
    },
    isValidToSubmit() {
      try {
        let returnData = null;
        returnData = function (key) {
          const current = this.modelProxy[key];
          if (!+current.supplier_company_id) return false;
          if (!current.dispatch_location_id) return false;
          if (!+current.amount) return false;
          if (!+current.currency_unit_id) return false;
          if (!+current.expiry_id) return false;
          if (!+current.price) return false;
          if (!+current.amount_unit_id) return false;
          if (!current.description) return false;
          if (!current.to_who) return false;
          if (!current.color_ok) return false;
          if (!current.fabric_type) return false;
          return true;
        }
        return returnData;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    sendMailUrl() {
      try {
        let returnData = null;
        returnData = function (key) {
          const itemId = this.modelProxy[key].id;
          return `api/fabric-request/${this.orderFabricId}/ready-made-request/${itemId}/share`
        }
        return returnData;
      } catch (e) {
        console.error(e);
        return false;
      }
    }
  },
  mounted() {
    this.modelProxy.forEach(model => {
      if (!+model.tolerance_amount) {
        model.fabric_supply_type_id = 5;
      }
      if (!model.color_ok) {
        model.color_ok = this.defaultColor.color
      }
      if(this.$route.query.qr_type=="fabric-request-ready"){
        setTimeout(() => {
          let instructionsId = this.$route.query.data.id
          let element = this.$refs["ready_"+instructionsId][0]
          if(element){
            element.scrollIntoView({behavior: "smooth"})
          }
        }, 1000);
    }
    })
  },
  watch: {
    modelProxy: {
      handler(newValue, oldValue) {
        if (newValue) {
          newValue.forEach(model => {
            model.fabric_supply_type_id = 5;
            model.tolerance_amount = this.maxTolerance;
            if (!model.color_ok && !model.id) {
              model.color_ok = this.defaultColor.color
            }
          })
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
