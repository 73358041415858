<template>
  <div class="px-4">
    <div class="row" :class="{'mt-5': index !== 0}" v-for="(value, index) in modelProxy.detail" :key="index">
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <custom-multi-select
            :min="1"
            :max="1"
            :is-inline="false"
            :options="supplierCompanies"
            :title="$t('sample.supplier_company')"
            :helper-text="$t(`sample.${type === 1 ? 'knitting' : 'raw_fabric'}_supplier_company_helper_text`)"
            :placeholder="$t('sample.multi_select')"
            input-width="150px"
            :model.sync="value.supplier_company_id"
            name="supplier_company"
            :not-list="true"
        ></custom-multi-select>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <custom-multi-select
            :options="fabricKnitTypes"
            :title="$t('sample.knitting_form')"
            :helper-text="$t(`sample.${type === 1 ? 'knitting' : 'raw_fabric'}_form_helper_text`)"
            :placeholder="$t('sample.multi_select')"
            :is-inline="false"
            input-width="150px"
            :model.sync="value.fabric_knit_type_id"
            :not-list="true"
            name="fabric_knit_type_id"
            :min="1"
            :max="1"
        ></custom-multi-select>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <text-input
            :title="$t('sample.fein_pus')"
            :is-inline="false"
            :helper-text="$t(`sample.fein_pus_helper_text`)"
            :model.sync="value.fein_pus"
            :unit-options="unitOptions"
        ></text-input>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <number-input
            :title="$t('sample.amount')"
            :helper-text="$t(`sample.${type === 1 ? 'painting_' : 'ready_made_'}amount_helper_text`)"
            :placeholder="$t(`sample.${type === 1 ? 'painting_' : 'ready_made_'}amount_helper_text`)"
            :name="`amount_${index}`"
            :model.sync="value.amount"
            :is-inline="false"
            :min="0"
            :step="1"
        ></number-input>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <custom-multi-select
            :max="1"
            :options="unitOptions"
            :title="$t('sample.unit_name')"
            :helper-text="$t(`sample.${type === 1 ? 'painting_' : 'ready_made_'}amount_helper_text`)"
            :placeholder="$t('sample.multi_select')"
            :model.sync="value.amount_unit_id"
            :is-inline="false"
            input-width="150px"
            name="value_amount_unit_id"
            :not-list="true"
        ></custom-multi-select>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <date-picker-input
            :title="$t('sample.flow_date')"
            :helper-text="$t(`sample.${type === 1 ? 'knitting' : 'raw_fabric'}_flow_date_helper_text`)"
            :placeholder="$t(`sample.${type === 1 ? 'knitting' : 'raw_fabric'}_flow_date_helper_text`)"
            :name="`yarn_flow_date_${index}`"
            :is-inline="false"
            :model.sync="value.flow_date"
        ></date-picker-input>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <date-picker-input
            :title="$t('sample.end_date')"
            :is-inline="false"
            :helper-text="$t(`sample.${type === 1 ? 'knitting' : 'raw_fabric'}_end_date_helper_text`)"
            :placeholder="$t(`sample.${type === 1 ? 'knitting' : 'raw_fabric'}_end_date_helper_text`)"
            :name="`yarn_end_date_${index}`"
            :model.sync="value.end_date"
        ></date-picker-input>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <number-input
            :title="$t('sample.price')"
            :helper-text="$t(`sample.${type === 1 ? 'knitting' : 'raw_fabric'}_price_helper_text`)"
            :model.sync="value.price"
            :is-inline="false"
            :min="0"
            :step="1"
        ></number-input>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <custom-multi-select
            :max="1"
            :options="currencyOptions"
            :title="$t('sample.unit_name')"
            :helper-text="$t(`sample.${type === 1 ? 'knitting' : 'raw_fabric'}_price_helper_text`)"
            :placeholder="$t('sample.multi_select')"
            :model.sync="value.currency_unit_id"
            :is-inline="false"
            input-width="150px"
            width="300px"
            name="value_amount_unit_id"
            :not-list="true"
        ></custom-multi-select>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <custom-multi-select
            :max="1"
            :options="dispatchLocations"
            :title="$t('sample.dispatch_location')"
            :placeholder="$t('sample.multi_select')"
            input-width="150px"
            :helper-text="$t(`sample.${type === 1 ? 'knitting' : 'raw_fabric'}_dispatch_location_helper_text`)"
            :model.sync="value.dispatch_location_id"
            :is-inline="false"
            width="300px"
            :name="`dispatch_location_${index}`"
            :not-list="true"
        ></custom-multi-select>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <custom-multi-select
            :max="1"
            :options="expirationTypeOptions"
            :title="$t('sample.payment_method')"
            :placeholder="$t('sample.multi_select')"
            :model.sync="value.expiry_id"
            :is-inline="false"
            input-width="150px"
            object-key="name"
            :item-per-row="2"
            name="expiration_type_options"
            :not-list="true"
            :show-invalid="false"
            :required="false"
        ></custom-multi-select>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
        <text-input
            :title="$t('sample.to_who')"
            :helper-text="$t('sample.to_who_helper_text')"
            :name="`to_who_${index}`"
            :is-inline="false"
            :model.sync="value.to_who"
            :required="false"
        ></text-input>
      </div>

      <div class="w-100 d-flex  mb-3 px-4" :class="index > 0 ? 'justify-content-between' : 'justify-content-end' ">
        <div v-if="index > 0">
          <button-with-icon
              v-if="index > 0"
              @onClick="deleteItem(index)"
              icon-name="icons.sample.delete_red"
              class="navy-blue-border-1px"
              :text="$t('general.delete')"
          ></button-with-icon>
        </div>
        <button-with-icon
            @onClick="value.showDescription = true"
            icon-name="icons.sample_instruction.add_2"
            class="navy-blue-border-1px"
            :text="$t('sample.add_description')"
        ></button-with-icon>
      </div>
      <div class="pt-5 pb-2 navy-blue-border-1px border-15px mb-4 w-100 mx-4 px-3"
           v-if="value.showDescription || value.description">
        <textarea-input
            :title="null"
            :model.sync="value.description"
            :required="false"
        ></textarea-input>
        <div class="w-50px ">
          <button-with-icon
              @onClick="hideShowDescription(index)"
              icon-name="icons.sample.delete_red"
              class="navy-blue-border-1px"
              :text="$t('general.delete')"
          ></button-with-icon>
        </div>
      </div>

      <div class="bottom-border-1px-navy py-0 w-100 opacity-50"
           v-if="(index !== modelProxy.detail.length - 1)"></div>
    </div>

    <div class="w-100 d-flex justify-content-between mb-0">
      <button-with-icon
          @onClick="addMore"
          icon-name="icons.sample_instruction.add_2"
          class="navy-blue-border-1px"
          :text="$t('sample.add_supplier')"
      ></button-with-icon>
      <button-with-icon
          @onClick="addMore"
          icon-name="icons.sample_instruction.add_2"
          class="navy-blue-border-1px"
          :text="$t('sample.add_dispatch_location')"
      ></button-with-icon>
    </div>
    <div class="w-100 my-2 d-flex justify-content-center">
      <div class="bottom-border-1px-navy mx-auto d-inline-block">
        <span class="d-inline-block text-2xl mr-10 font-weight-bolder">{{ $t('sample.total_price') }}</span>
        <span class="text-2xl">{{ totalPrice }}
          {{ modelProxy.detail[0].currency_unit_id ? currencyOptions[modelProxy.detail[0].currency_unit_id] : ''}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import NumberInput from "@/assets/components/inputs/NumberInput";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import NumberInputWithUnit from "@/assets/components/inputs/NumberInputWithUnit";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import TextInput from "@/assets/components/inputs/TextInput";

export default {
name: "KnittingAndRawFabricForm",
  components: {
    TextInput,
    CustomMultiSelect,
    NumberInput,
    DatePickerInput,
    NumberInputWithUnit,
    TextareaInput,
    ButtonWithIcon,
  },
  props: {
    model: {
      required: true,
    },
    supplierCompanies: {
      required: true,
    },
    expirationTypeOptions: {
      required: true,
    },
    expiryId: {
      required: false,
    },
    currencyOptions: {
      required: true,
    },
    unitOptions: {
      required: true,
    },
    type: {
      required: true,
      validation: function(l){
        return [1, 2].indexOf(l) > -1; // 1 => knitting, 2 => raw fabric
      }
    },
    fabricKnitTypes: {
      required: true,
    },
    dispatchLocations: {
      required: true,
    },
    currency_unit_id: {
      required: true,
    }
  },
  computed: {
    modelProxy: {
      get(){
        return this.model;
      },
      set(value) {
        this.$emit('update:model', value)
      }
    },
    totalPrice(){
      let total = 0;
      for(let i = 0; i < this.modelProxy.detail.length; i++){
        total += Number(this.modelProxy.detail[i].amount) * Number(this.modelProxy.detail[i].price);
      }
      return total.toFixed(2);
    }
  },
  methods: {
    hideShowDescription(index){
      this.modelProxy.detail[index].description = '';
      this.modelProxy.detail[index].showDescription  = false;
    },
    addMore(){
      this.modelProxy.detail.push({...this.modelProxy.detail.slice(-1)[0], showDescription: false, description: null})
    },
    deleteItem(index){
      console.log(index)
      this.modelProxy.detail.splice(index,1);
    }
  }
}
</script>

<style scoped>

</style>
